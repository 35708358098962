
import { Tooltip } from "@material-ui/core";
import styled, { css } from "styled-components/macro";
import Icon from '@mdi/react';
import { mdiHarddisk, mdiMemory, mdiCpu64Bit, mdiExpansionCard, mdiHelpCircle } from '@mdi/js';

export const SectionHeading = styled.h2`
  color: var(--color-foreground);
  font-size: 1.25rem;
`;

const Node = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
`;

const NodeTop = styled.div`
left: 15px;
top: 12px;
font-family: 'Noto Sans';
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #273139;
margin: 5px;
`;

const NodeDescription = styled.div`
  background-color: #ffffff;
  display: flex;
  margin: 10px;
  padding: 10px;
  font-size: 16px;
  flex-direction: column;
    align-content: flex-start;
    align-items: flex-start;
    flex-wrap: nowrap;
  border-radius: 6px;
`;

const Recommendation = styled.div`
  display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const NodeWidget = ({
    nodeData,
}: {
    nodeData: NodeDataType;
}) => {
    var nodeCount = <span>{nodeData.nodeCount}</span>;
    if (nodeData.nodeCountTooltip) {
        nodeCount = <Tooltip title={nodeData.nodeCountTooltip as string}>{nodeCount}</Tooltip>;
    }
    var nodeType = <span>{nodeData.nodeType}</span>;
    if (nodeData.nodeTypeTooltip) {
        nodeType = <Tooltip title={nodeData.nodeTypeTooltip as string}>{nodeType}</Tooltip>;
    }
    return (
        <Node>
            <NodeDescription>
                <NodeTop>
                    <span>{nodeCount} {nodeType}</span>
                </NodeTop>
                {!!nodeData.cpu && (
                    <Recommendation>
                        <Icon path={mdiCpu64Bit} size={1} />
                        <span>{nodeData.cpu}</span>
                    </Recommendation>
                )}
                {!!nodeData.ram && (
                    <Recommendation>
                        <Icon path={mdiMemory} size={1} />
                        <span>{nodeData.ram}</span>
                    </Recommendation>
                )}
                {!!nodeData.drives && (
                    nodeData.drives.map(drive => {
                        return (
                            <Recommendation>
                                <Icon path={mdiHarddisk} size={1} />
                                <span>{drive}</span>
                            </Recommendation>
                        )
                    })
                )}
                {!!nodeData.gpu && (
                    <Recommendation>
                        <Icon path={mdiExpansionCard} size={1} />
                        <span>{nodeData.gpu}</span>
                    </Recommendation>
                )}
            </NodeDescription>
        </Node>
    );
};

export default NodeWidget;
