import orchestratorIcon from "./../assets/productIcons/orchestrator.png"
import actionCenterIcon from "./../assets/productIcons/actioncenter.png"
import aiCenterIcon from "./../assets/productIcons/aIcenter.png"
import appsIcon from "./../assets/productIcons/apps.png"
import automationHubIcon from "./../assets/productIcons/automationhub.png"
import automationOpsIcon from "./../assets/productIcons/automationops.png"
import dataServiceIcon from "./../assets/productIcons/dataservice.png"
import duIcon from "./../assets/productIcons/du.png"
import insightsIcon from "./../assets/productIcons/insights.png"
import processMiningIcon from "./../assets/productIcons/processmining.png"
import taskMiningIcon from "./../assets/productIcons/taskmining.png"
import testManagerIcon from "./../assets/productIcons/testmanager.png"
export const DEFAULT_CONCURRENT_USERS_NUMBER = 5;


enum Profile {
  EvaluationOnly = "Evaluation Only",
  HAProduction = "Production - HA Ready",
}

export const ICONS =new Map<string, any>([
    ['platform', orchestratorIcon],
    ['orchestrator', orchestratorIcon],
    ['action_center', actionCenterIcon],
    ['apps', appsIcon],
    ['ai_center', aiCenterIcon],
    ['automation_hub', automationHubIcon],
    ['task_mining', taskMiningIcon],
    ['document_understanding', duIcon],
    ['insights', insightsIcon],
    ['automation_ops', automationOpsIcon],
    ['test_manager', testManagerIcon],
    ['data_service', dataServiceIcon],
    ['process_mining', processMiningIcon],
    ['automation_suite_robots', orchestratorIcon]
])

export const PLATFORM_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Shared Suite Capabilities",
  key: "platform",
  isCoreProduct: 1,
  isStartingProduct: 1,
  canBeRemoved: 1,
  properties: {
    loginPerSecond: 1,
    concurrentUsers: 5,
  },
};

export const ORCHESTRATOR_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Orchestrator",
  key: "orchestrator",
  isCoreProduct: 1,
  isStartingProduct: 1,
  canBeRemoved: 1,
  properties: {
    concurrentRobots: 5,
  },
};

export const ACTION_CENTER_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Action Center",
  key: "action_center",
  isCoreProduct: 1,
  isStartingProduct: 1,
  canBeRemoved: 1,
  properties: {
    concurrentUsers: DEFAULT_CONCURRENT_USERS_NUMBER,
  },
};

export const BUSINESS_APPS_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Apps",
  key: "apps",
  isCoreProduct: 0,
  isStartingProduct: 0,
  canBeRemoved: 1,
  properties: {
    concurrentUsers: DEFAULT_CONCURRENT_USERS_NUMBER,
  },
};
export const AI_CENTER_PRODUCT_OPTION: ProductOptionInterface = {
  name: "AI Center",
  key: "ai_center",
  isCoreProduct: 0,
  isStartingProduct: 0,
  canBeRemoved: 1,
  properties: {
    numberOfSkills: 0,
    numberOfPipelines: 0,
  },
};

export const AUTOMATION_HUB_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Automation Hub",
  key: "automation_hub",
  isCoreProduct: 1,
  isStartingProduct: 1,
  canBeRemoved: 1,
  properties: {
    concurrentUsers: DEFAULT_CONCURRENT_USERS_NUMBER,
  },
};

export const TASK_MINING_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Task Mining",
  key: "task_mining",
  isCoreProduct: 0,
  isStartingProduct: 0,
  canBeRemoved: 1,
  properties: {
    concurrentUsers: DEFAULT_CONCURRENT_USERS_NUMBER,
  },
};

export const DOCUMENT_UNDERSTANDING_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Document Understanding",
  key: "document_understanding",
  isCoreProduct: 0,
  isStartingProduct: 0,
  canBeRemoved: 1,
  properties: {
    generalExtractorPerHour: 0,
    invoicesPerHour: 0,
    receiptsPerHour: 0,
    purchaseOrdersPerHour: 0,
    utilityBillsPerHour: 0,
    ocrPerHour: 0,
    shredsPerHour: 0,
  },
};

export const INSIGHTS_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Insights",
  key: "insights",
  isCoreProduct: 1,
  isStartingProduct: 1,
  canBeRemoved: 1,
  properties: {},
};

export const AUTOMATION_OPS_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Automation Ops",
  key: "automation_ops",
  isCoreProduct: 1,
  isStartingProduct: 1,
  canBeRemoved: 1,
  properties: {},
};

export const TEST_MANAGER_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Test Manager",
  key: "test_manager",
  isCoreProduct: 1,
  isStartingProduct: 1,
  canBeRemoved: 1,
  properties: {
    concurrentUsers: DEFAULT_CONCURRENT_USERS_NUMBER,
  },
};

export const DATA_SERVICE_PRODUCT_OPTION: ProductOptionInterface = {
  name: "Data Service",
  key: "data_service",
  isCoreProduct: 1,
  isStartingProduct: 1,
  canBeRemoved: 1,
  properties: {
    concurrentUsers: DEFAULT_CONCURRENT_USERS_NUMBER,
  },
};

export const PROCESS_MINING_PRODUCT_OPTION: ProductOptionInterface = {
    name: "Process Mining",
    key: "process_mining",
    isCoreProduct: 0,
    isStartingProduct: 0,
    canBeRemoved: 1,
    properties: {
        eventsInMillions: 0,
        caseAndEventFields: 0,
    },
};

export const AUTOMATION_SUITE_ROBOTS_PRODUCT_OPTION: ProductOptionInterface = {
    name: "Automation Suite Robots",
    key: "automation_suite_robots",
    isCoreProduct: 0,
    isStartingProduct: 0,
    canBeRemoved: 1,
    properties: {
        concurrentSmallRobots: 5,
        concurrentStandardRobots: 0,
        concurrentMediumRobots: 0,
        concurrentLargeRobots: 0,
    },
};

export const ALL_PRODUCT_OPTIONS = [
  PLATFORM_PRODUCT_OPTION,
  ORCHESTRATOR_PRODUCT_OPTION,
  ACTION_CENTER_PRODUCT_OPTION,
  BUSINESS_APPS_PRODUCT_OPTION,
  AI_CENTER_PRODUCT_OPTION,
  AUTOMATION_HUB_PRODUCT_OPTION,
  TASK_MINING_PRODUCT_OPTION,
  DOCUMENT_UNDERSTANDING_PRODUCT_OPTION,
  INSIGHTS_PRODUCT_OPTION,
  AUTOMATION_OPS_PRODUCT_OPTION,
  TEST_MANAGER_PRODUCT_OPTION,
  DATA_SERVICE_PRODUCT_OPTION,
  PROCESS_MINING_PRODUCT_OPTION,
  AUTOMATION_SUITE_ROBOTS_PRODUCT_OPTION
];

export const CORE_PRODUCT_OPTIONS = ALL_PRODUCT_OPTIONS.filter(
  (option) => !!option.isCoreProduct
);

export const STARTING_PRODUCT_OPTIONS = ALL_PRODUCT_OPTIONS.filter(
  (option) => !!option.isStartingProduct
);
