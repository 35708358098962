import queryString from "query-string";

export const jsonToQueryParamData = (data: CalculatorFormDataType) => {
  let processedData = "";
  try {
    processedData = btoa(JSON.stringify(data));
  } catch (e) {}
  const params = {
    data: processedData,
  };
  return queryString.stringify(params);
};

export const getDecodedParamsJsonOrNull = (params: string) => {
  const parsedParams: any = queryString.parse(params);
  if (parsedParams && parsedParams.data) {
    try {
      parsedParams.data = JSON.parse(atob(parsedParams.data || " "));
      if (parsedParams.data && typeof parsedParams.data === "object") {
        return parsedParams.data;
      }
    } catch (e) {
      console.log({ e });
      alert("Error loading shared calculation");
    }
    return null;
  }
};
