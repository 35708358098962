import React, { useState } from "react";
import {
    DialogTitle,
    DialogContent,
    Dialog,
    DialogActions,
    Icon,
    IconButton,
    FormControlLabel,
    Checkbox,
    Tooltip,
} from "@material-ui/core";
import {
    ButtonApolloPrimary,
    ButtonApolloSecondary,
} from "../../../Button/Button";
import { ALL_PRODUCT_OPTIONS } from "../../../../constants/calculatorConstants";
import styled from "styled-components";
import * as constants from "../../../../constants/calculatorConstants"

const DialogStyled = styled(Dialog)`
  background: rgba(0, 0, 0, 0.3);

  &.MuiDialog-root.alert .container {
    align-items: center;
  }
`;

const AddProductModal = ({
                             onClose,
                             selectedProducts,
                             onSelectedProductsChange,
                         }: {
    onClose: () => void;
    selectedProducts: ProductOptionInterface[];
    onSelectedProductsChange: (data: ProductOptionInterface[]) => void;
}) => {
    const [updatedSelectedProducts, setUpdatedSelectedProducts] = useState([
        ...(selectedProducts || []),
    ]);

    const handleCheckboxChange = (clickedOption: any) => {
        if (clickedOption && updatedSelectedProducts) {
            const currentOptionIndex = updatedSelectedProducts.findIndex(
                (option: any) => {
                    return option.key === clickedOption.key;
                }
            );

            if (currentOptionIndex === -1) {
                let newOptions: ProductOptionInterface[] = [clickedOption as ProductOptionInterface];

                console.log(clickedOption.key, constants.DOCUMENT_UNDERSTANDING_PRODUCT_OPTION);
                console.log(clickedOption.key, constants.TASK_MINING_PRODUCT_OPTION);
                console.log(updatedSelectedProducts.findIndex(
                    (option: any) => {
                        return option.key === constants.AI_CENTER_PRODUCT_OPTION.key;
                    }));

                // Handle dependencies
                if (clickedOption.key === constants.DOCUMENT_UNDERSTANDING_PRODUCT_OPTION.key
                    || clickedOption.key === constants.TASK_MINING_PRODUCT_OPTION.key) {
                    let isAICSelected = updatedSelectedProducts.findIndex(
                        (option: any) => {
                            return option.key === constants.AI_CENTER_PRODUCT_OPTION.key;
                        }) >= 0;
                    if (!isAICSelected) {
                        newOptions.push(constants.AI_CENTER_PRODUCT_OPTION);
                    }
                }
                if (clickedOption.key === constants.ACTION_CENTER_PRODUCT_OPTION.key
                    || clickedOption.key === constants.INSIGHTS_PRODUCT_OPTION.key
                    || clickedOption.key === constants.AUTOMATION_SUITE_ROBOTS_PRODUCT_OPTION.key
                    || clickedOption.key === constants.TEST_MANAGER_PRODUCT_OPTION.key) {
                    let isOrchestratorSelected = updatedSelectedProducts.findIndex(
                        (option: any) => {
                            return option.key === constants.ORCHESTRATOR_PRODUCT_OPTION.key;
                        }) >= 0;
                    if (!isOrchestratorSelected) {
                        newOptions.push(constants.ORCHESTRATOR_PRODUCT_OPTION);
                    }
                }

                console.log(newOptions);
                setUpdatedSelectedProducts([
                    ...updatedSelectedProducts,
                    ...newOptions,
                ]);
            } else {
                setUpdatedSelectedProducts(
                    updatedSelectedProducts.filter(
                        (product) => product.key !== clickedOption.key
                    )
                );
            }
        }
    };

    const disabledMap = selectedProducts.reduce((accum: any, option: any) => {
        accum[option.key] = true;
        return accum;
    }, {});

    const checkedMap = updatedSelectedProducts.reduce(
        (accum: any, option: any) => {
            accum[option.key] = !!updatedSelectedProducts.find(
                (innerOption: any) => innerOption.key === option.key
            );
            return accum;
        },
        {}
    );

    const handleAddClick = () => {
        onSelectedProductsChange(updatedSelectedProducts);
        onClose();
    };

    const generateOptions = () => {
        return ALL_PRODUCT_OPTIONS.map((option) => {
            let isDUSelected = updatedSelectedProducts.findIndex(
                (option: any) => {
                    return option.key === constants.DOCUMENT_UNDERSTANDING_PRODUCT_OPTION.key;
                }) >= 0;
            let isTMSelected = updatedSelectedProducts.findIndex(
                (option: any) => {
                    return option.key === constants.TASK_MINING_PRODUCT_OPTION.key;
                }) >= 0;

            let isOrchDepSelected = updatedSelectedProducts.findIndex(
                (option: any) => {
                    return (option.key === constants.ACTION_CENTER_PRODUCT_OPTION.key
                        || option.key === constants.INSIGHTS_PRODUCT_OPTION.key
                        || option.key === constants.TEST_MANAGER_PRODUCT_OPTION.key
                        || option.key === constants.AUTOMATION_SUITE_ROBOTS_PRODUCT_OPTION.key);
                }) >= 0;

            let isOtherServiceSelected = updatedSelectedProducts.findIndex(
                (option: any) => {
                    return (option.key === constants.BUSINESS_APPS_PRODUCT_OPTION.key
                        || option.key === constants.AUTOMATION_HUB_PRODUCT_OPTION.key
                        || option.key === constants.AUTOMATION_OPS_PRODUCT_OPTION.key
                        || option.key === constants.DATA_SERVICE_PRODUCT_OPTION.key
                        || option.key === constants.PROCESS_MINING_PRODUCT_OPTION.key);
                }) >= 0;
            let isAiCenterSelected = updatedSelectedProducts.findIndex(
                (option: any) => {
                    return option.key === constants.AI_CENTER_PRODUCT_OPTION.key;
                }) >= 0;
            let isExternalOrchestratorEnabled = false;
            if (isAiCenterSelected) {
                let aiCenterProduct = updatedSelectedProducts.filter(p => p.key == constants.AI_CENTER_PRODUCT_OPTION.key)[0];
                if (aiCenterProduct.properties !== undefined) {
                    isExternalOrchestratorEnabled = !!aiCenterProduct.properties["externalOrchestrator"];
                }
            }

            let isDisabled: boolean = !option.canBeRemoved;
            let tooltip: string = "";
            if (option.key === constants.AI_CENTER_PRODUCT_OPTION.key
                && (isDUSelected || isTMSelected)) {
                isDisabled = true;
                tooltip = "Task Mining and Document Understanding depend on AI Center";
            }
            if (option.key === constants.ORCHESTRATOR_PRODUCT_OPTION.key
                && (isOrchDepSelected)) {
                isDisabled = true;
                tooltip = "Action Center, Insights, Test Manager, Automation Suite Robots depend on Orchestrator";
            }
            if (option.key === constants.PLATFORM_PRODUCT_OPTION.key
                && (!(isExternalOrchestratorEnabled && !isOtherServiceSelected))) {
                isDisabled = true;
            }


            return (
                <div key={option.key}>
                    <Tooltip title={tooltip}>
                        <FormControlLabel
                            disabled={isDisabled}
                            control={
                                <Checkbox
                                    onChange={() => handleCheckboxChange(option)}
                                    checked={!!checkedMap[option.key]}
                                    name={option.key}
                                />
                            }
                            label={option.name}
                        />
                    </Tooltip>
                </div>
            );
        });
    };

    return (
        <DialogStyled className="alert" open={true} onClose={onClose}>
            <DialogTitle>
                <div className="container">
                    Products
                    <div
                        style={{
                            flex: "1 0 0",
                        }}
                    />
                    <IconButton onClick={onClose}>
                        <Icon>close</Icon>
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>{generateOptions()}</DialogContent>
            <DialogActions>
                <ButtonApolloSecondary onClick={onClose}>Cancel</ButtonApolloSecondary>
                <ButtonApolloPrimary
                    onClick={handleAddClick}
                    disabled={selectedProducts.length === updatedSelectedProducts.length}
                >
                    Add
                </ButtonApolloPrimary>
            </DialogActions>
        </DialogStyled>
    );
};

export default AddProductModal;