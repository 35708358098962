import { FormHelperText, Tooltip } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components/macro";
import { calculateRequirements } from "../../../utils/calculationUtils";
import { ButtonApolloSecondary } from "../../Button/Button";
import NodeWidget from "./NodeWidget";
import StorageWidget  from "./StorageWidget";
import {mdiHarddisk, mdiMemory, mdiCpu64Bit, mdiExpansionCard, mdiAws, mdiMicrosoftAzure} from '@mdi/js';
import {IconButton, Accordion, AccordionSummary, AccordionDetails, Typography } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import 'github-markdown-css/github-markdown-light.css';
import Icon from '@mdi/react';
import awsLogo from "./../../../assets/images/aws-s3-icon.svg";
import azureLogo from "./../../../assets/images/azure-storage-logo.png";
import s3Logo from "./../../../assets/images/s3Logo.jpg";




const IconLeftAccordionSummary = withStyles({
  expandIcon: {
    order: -1,
    right: '1%',
  }
})(AccordionSummary);

const Container = styled.div<{ $isAnimating: boolean }>(
  (props) => css`
  border: 1px solid var(--color-border);
  background: var(--color-background);
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;

    ${props.$isAnimating ? `animation: animate-value-text 1s infinite;` : ""}

    @keyframes animate-value-text {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
`);

export const SectionHeading = styled.h2`
  color: var(--color-foreground);
  font-size: 1.25rem;
`;

const StatsContainer = styled.code`
  display: block;
  background: var(--color-background-secondary);
  padding: 8px;
  margin: 8px 0;

  b {
    color: var(--color-foreground);
  }

  small {
    font-size: 12px;
  }
`;


const ValueText = styled.span<{ $isAnimating: boolean }>(
  (props) => css`
    font-family: monospace;
    ${props.$isAnimating ? `animation: animate-value-text 1s infinite;` : ""}

    @keyframes animate-value-text {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }
  `
);

const FlexSection = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 20px;
  background-color: #f1f6f8;
  margin: 10px;
`;

const FlexSection2 = styled.div`
  display: flex;
  height: 48px;
  left: 0px;
  top: 164px;
  border-radius: 3px;
  background: #F1F6F8;
  margin: 10px;
`;

const Typography1 = styled.div`
  height: 20px;
  padding-left: 18px;
  top: 178px;
  font-family: 'Noto Sans';
  font-style: normal;
  // font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #273139;
`;

const Recommendation = styled.div`
  display: flex;
    flex-direction: row;
    padding-top: 14px;
    padding-left: 20px;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const GitHubMarkdown = styled.div`
  ul {
    list-style: disc;
  }
`;


let animationTimeout: any = null;

export const RecommendationWidget = ({
  formData,
  className,
}: {
  formData: CalculatorFormDataType;
  className?: string;
}) => {
  const recommendation = calculateRequirements(formData);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    clearTimeout(animationTimeout);
    setIsAnimating(true);

    animationTimeout = setTimeout(() => {
      setIsAnimating(false);
    }, 1000);
  }, [formData]);

  const source = `
## Server Nodes

- Line 1
- Line 2
- Line [3](www.google.com)

### Breakdown
* Test
* A
`;

  const azureStore : StorageType = {
    iconPath: azureLogo,
    storageType: "Object Store",
    storageName: "Azure Storage"
  }
  const awsStore : StorageType = {
    iconPath: awsLogo,
    storageType: "Object Store",
    storageName: "AWS S3"
  }
  const s3Store : StorageType = {
    iconPath: s3Logo,
    storageType: "Object Store",
    storageName: "S3 Compatible Storage"
  }

  console.log("Debug: ", recommendation.debug);

  return (
    <Container className={className} $isAnimating={isAnimating}>
      <Accordion defaultExpanded={true}>
        <IconLeftAccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h3">Minimal Recommended Configuration</Typography>
        </IconLeftAccordionSummary>

        {!!recommendation.error && (
          <FormHelperText error>
            {recommendation.error}
          </FormHelperText>
        )}
        {!!recommendation.nodes && (
          <FlexSection>
            {recommendation.nodes.map(node => <NodeWidget nodeData={node} />)}
          </FlexSection>
        )}
        {formData.highLevelParameters.host === "AWS" &&(
            <FlexSection2>
              <StorageWidget storageData={awsStore} />
            </FlexSection2>
        )}
        {formData.highLevelParameters.host === "Azure" &&(
            <FlexSection2>
              <StorageWidget storageData={azureStore} />
            </FlexSection2>
        )}
        {formData.highLevelParameters.host === "On-Prem" && formData.highLevelParameters.externalS3 &&(
            <FlexSection2>
              <StorageWidget storageData={s3Store} />
            </FlexSection2>
        )}
        {!!recommendation.sqlNodes && (
            <FlexSection>
              {recommendation.sqlNodes?.map(
                  node => <NodeWidget nodeData={node} />
              )}
            </FlexSection>
        )}
        {!!recommendation.nfsNode && (
            <FlexSection>
              <NodeWidget nodeData={recommendation.nfsNode} />
            </FlexSection>
        )}
      </Accordion>
      {!!recommendation.debug && (
        <Accordion defaultExpanded={true}>
          <IconLeftAccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h3">Details</Typography>
          </IconLeftAccordionSummary>
          <AccordionDetails>
            <div className='markdown-body'>
              <GitHubMarkdown>
                <ReactMarkdown children={recommendation.debug.join("\n")} remarkPlugins={[remarkGfm]} />
              </GitHubMarkdown>
            </div>
          </AccordionDetails>
        </Accordion>
      )}
    </Container>
  );
};

export default RecommendationWidget;