import styled, { css } from "styled-components/macro";
import React from "react";


const Recommendation = styled.div`
  display: flex;
    flex-direction: row;
    padding-top: 14px;
    padding-left: 8px;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;
`;

const Typography1 = styled.div`
  height: 20px;
  padding-left: 18px;
  top: 178px;
  font-family: 'Noto Sans';
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  color: #273139;
`;

const StorageType = styled.span`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #273139;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  
`;

const StorageWidget = ({
                        storageData,
                    }: {
    storageData: StorageType;
}) => {
    var storageIcon = storageData.iconPath;
    var storageType = storageData.storageType;
    var storageName = storageData.storageName;
    return (
        <Flex>
            <Typography1>
                <Recommendation>
                    <StorageType>{storageType}</StorageType>
                </Recommendation>
            </Typography1>
            <Typography1>
                <Recommendation>
                    <img src={storageIcon}/>
                    <span>{storageName}</span>
                </Recommendation>
            </Typography1>
        </Flex>
    );
};

export default StorageWidget;