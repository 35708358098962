import * as constants from '../constants/calculatorConstants'

enum Profile {
  EvaluationOnly = "Evaluation Only",
  HAProduction = "Production - HA Ready",
}

const serverType = "Server";
const serverTypeTooltip = "Kubernetes Control Plane Node";
const agentType = "Agent";
const agentTypeTooltip = "Kubernetes Worker Node";
const duType = "Dedicated";
const duTypeTooltip = "Node for Document Understanding";
const tmType = "Dedicated";
const tmTypeTooltip = "Node for Task Mining";
const asRobotsType = "Dedicated";
const asRobotsTypeTooltip = "Node for Automation Suite Robots";
const pmType = " Dedicated SQL Server";
const pmTypeTooltip = "SQL Server for Process Mining";
const nfsType = "NFS";
const nfsTypeTooltip = "NFS Server for Backup Process";
const sqlType = "Shared SQL Server";
const sqlTypeTooltip = "SQL Server for selected products";


const duNode = (count: number): NodeDataType => {
  return {
    nodeType: duType,
    nodeTypeTooltip: duTypeTooltip,
    nodeCount: count.toString(),
    cpu: "8 vCPU",
    ram: "52 GiB",
    drives: [
      "256 GiB SSD"
    ],
    gpu: "NVIDIA"
  }
};

const tmNode: NodeDataType = {
  nodeType: tmType,
  nodeTypeTooltip: tmTypeTooltip,
  nodeCount: "1",
  cpu: "20 vCPU",
  ram: "60 GiB",
  drives: [
    "256 GiB SSD"
  ],
};

const nfsNode: NodeDataType = {
  nodeType: nfsType,
  nodeTypeTooltip: nfsTypeTooltip,
  nodeCount: "1",
  cpu: "4 vCPU",
  ram: "8 GiB",
  drives: [
      "2 TiB SSD"
  ],
};

const static_reqs = new Map([
  [constants.PLATFORM_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 3410,
      ram: 5700,
      dataDisk: 170,
    }],
    [Profile.HAProduction, {
      cpu: 7910,
      ram: 17500,
      dataDisk: 170,
    }],
  ])],
  ["fabric", new Map([
    [Profile.EvaluationOnly, {
      cpu: 6493,
      ram: 12338,
      dataDisk: 170,
    }],
    [Profile.HAProduction, {
      cpu: 13838,
      ram: 32574,
      dataDisk: 170,
    }],
  ])],
  [constants.ORCHESTRATOR_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 790,
      ram: 3000,
      dataDisk: 5,
    }],
    [Profile.HAProduction, {
      cpu: 3400,
      ram: 7856,
      dataDisk: 10,
    }],
  ])],
  [constants.ACTION_CENTER_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 730,
      ram: 2100,
      dataDisk: 5,
    }],
    [Profile.HAProduction, {
      cpu: 1700,
      ram: 4700,
      dataDisk: 10,
    }],
  ])],
  [constants.BUSINESS_APPS_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 505,
      ram: 1500,
      dataDisk: 160,
    }],
    [Profile.HAProduction, {
      cpu: 1250,
      ram: 3500,
      dataDisk: 160,
    }],
  ])],
  [constants.AUTOMATION_HUB_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 590,
      ram: 1400,
      dataDisk: 0,
    }],
    [Profile.HAProduction, {
      cpu: 1500,
      ram: 3500,
      dataDisk: 0,
    }],
  ])],
  [constants.TASK_MINING_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 4060,
      ram: 5800,
      dataDisk: 1000,
    }],
    [Profile.HAProduction, {
      cpu: 8250,
      ram: 11600,
      dataDisk: 2000,
    }],
  ])],
  [constants.DOCUMENT_UNDERSTANDING_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 2220,
      ram: 4200,
      dataDisk: 0,
    }],
    [Profile.HAProduction, {
      cpu: 6600,
      ram: 12800,
      dataDisk: 0,
    }],
  ])],
  [constants.INSIGHTS_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 330,
      ram: 1700,
      disk: 102,
    }],
    [Profile.HAProduction, {
      cpu: 1350,
      ram: 5000,
      dataDisk: 102,
    }],
  ])],
  [constants.AUTOMATION_OPS_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 170,
      ram: 700,
      dataDisk: 0,
    }],
    [Profile.HAProduction, {
      cpu: 500,
      ram: 1700,
      dataDisk: 0,
    }],
  ])],
  [constants.TEST_MANAGER_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 410,
      ram: 950,
      dataDisk: 0,
    }],
    [Profile.HAProduction, {
      cpu: 900,
      ram: 2000,
    }],
  ])],
  [constants.AI_CENTER_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 2990,
      ram: 7756,
      dataDisk: 0,
    }],
    [Profile.HAProduction, {
      cpu: 6700,
      ram: 16924,
    }],
  ])],
  [constants.DATA_SERVICE_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
      cpu: 60,
      ram: 378,
    }],
    [Profile.HAProduction, {
      cpu: 300,
      ram: 1000,
    }],
  ])],
  [constants.PROCESS_MINING_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
        cpu: 3220,
        ram: 3550,
    }],
    [Profile.HAProduction, {
        cpu: 4862,
        ram: 6228,
    }],
  ])],
  [constants.AUTOMATION_SUITE_ROBOTS_PRODUCT_OPTION.key, new Map([
    [Profile.EvaluationOnly, {
        cpu: 500,
        ram: 712,
    }],
    [Profile.HAProduction, {
        cpu: 1000,
        ram: 1424,
        dataDisk: 10,
    }],
  ])],
]);

function formatBytes(bytes: number, decimals = 1) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

function formatCpu(cpu: number, decimals = 1) {
  if (cpu === 0) return '0 vCPU';
  const dm = decimals < 0 ? 0 : decimals;
  return parseFloat((cpu / 1000.0).toFixed(dm)) + " vCPU";
}

function getMultizonalFactor(nodes: number) {
  console.log(nodes)
  let factor = 1.5;
  if (nodes % 3 === 1) {
    factor = nodes/(2*(Math.floor(nodes/3)));
  }
  else if (nodes % 3 === 2) {
    factor = 1.5 + 1/(2*(Math.ceil(nodes/3)-1));
  }
  console.log(factor)

  return factor;
}

const additionalRequirements = (dimensionName: string, dimensionValue: number, cpu: number, ram: number): string => {
  console.log("Name: ", dimensionName, ", Value: ", dimensionValue, cpu, ram)
  if (dimensionValue === 0) {
    return "";
  }
  if (cpu === 0 && ram === 0) {
    return `* Handling **${dimensionValue}** of **${dimensionName}** can be handled by the base replicas.`;
  }
  return `* Handling **${dimensionValue}** of **${dimensionName}** requires an additional **${formatCpu(cpu, 1)}** / **${formatBytes(ram * 1024 * 1024, 1)}**.`;
};


export const calculateRequirements = (
  formData: CalculatorFormDataType
): RecommendationDataType => {

  console.log(formData);

  let profile = formData.highLevelParameters.profile === "ha" ? Profile.HAProduction : Profile.EvaluationOnly;

  let onlyCoreProducts = true;
  let isTaskMiningEnabled = false;
  let isAutomationSuiteRobotsEnabled = false;
  let isProcessMiningEnabled = false;

  for (let product of formData.products) {
    if (product.isCoreProduct === 0) {
      onlyCoreProducts = false;
    }
    if (product.key === constants.TASK_MINING_PRODUCT_OPTION.key) {
      isTaskMiningEnabled = true;
    }
    if (product.key === constants.AUTOMATION_SUITE_ROBOTS_PRODUCT_OPTION.key) {
      isAutomationSuiteRobotsEnabled = true;
    }
    if (product.key === constants.PROCESS_MINING_PRODUCT_OPTION.key) {
      isProcessMiningEnabled = true;
    }
  }

  let osDisk = "256 GiB SSD";

  // Document Understanding GPU recommendation
  let duGpuCount = 0;
  let duProducts = formData.products.filter(p => p.key == constants.DOCUMENT_UNDERSTANDING_PRODUCT_OPTION.key);
  let retrainingRequired = false;
  let ocrGpuRequired = false;
  if (duProducts.length > 0) {
    let duProduct = duProducts[0];
    if (duProduct.properties !== undefined) {
      let ocrPerHour = +duProduct.properties["ocrPerHour"];
      if (ocrPerHour > 60) {
        duGpuCount += 1
        ocrGpuRequired = true;
      }
      retrainingRequired = !!duProduct.properties["retrainingRequired"];
      if (retrainingRequired) {
        duGpuCount += 1
      }
    }
  }

  // Automation Suite Robots additional node recommendation
  let asRobotNodeCpu = 0;
  let asRobotNodeRam = 0;
  let asRobotNodeDisk = 10;
  let isAdditionalNodeNeededEval = false;
  if (isAutomationSuiteRobotsEnabled) {
    let asRobotProduct = formData.products.filter(p => p.key == constants.AUTOMATION_SUITE_ROBOTS_PRODUCT_OPTION.key)[0];
    if (asRobotProduct.properties !== undefined) {
      asRobotNodeCpu += asRobotProduct.properties["concurrentSmallRobots"] * 500;
      asRobotNodeCpu += asRobotProduct.properties["concurrentStandardRobots"] * 1000;
      asRobotNodeCpu += asRobotProduct.properties["concurrentMediumRobots"] * 2000;
      asRobotNodeCpu += asRobotProduct.properties["concurrentLargeRobots"] * 6000;
      asRobotNodeRam += asRobotProduct.properties["concurrentSmallRobots"] * 1 * 1024 * 1024 * 1024;
      asRobotNodeRam += asRobotProduct.properties["concurrentStandardRobots"] * 2 * 1024 * 1024 * 1024;
      asRobotNodeRam += asRobotProduct.properties["concurrentMediumRobots"] * 4 * 1024 * 1024 * 1024;
      asRobotNodeRam += asRobotProduct.properties["concurrentLargeRobots"] * 10 * 1024 * 1024 * 1024;
      asRobotNodeDisk += asRobotProduct.properties["concurrentSmallRobots"] * 1;
      asRobotNodeDisk += asRobotProduct.properties["concurrentStandardRobots"] * 2;
      asRobotNodeDisk += asRobotProduct.properties["concurrentMediumRobots"] * 4;
      asRobotNodeDisk += asRobotProduct.properties["concurrentLargeRobots"] * 10;
      isAdditionalNodeNeededEval = (asRobotProduct.properties["concurrentSmallRobots"] > 5 ||
                                asRobotProduct.properties["concurrentStandardRobots"] > 0 ||
                                asRobotProduct.properties["concurrentMediumRobots"] > 0 ||
                                asRobotProduct.properties["concurrentLargeRobots"] > 0 )
    }
  }

  // Process Mining additional sql requirements
  let pmDiskSpace = 0;
  let pmTempDbSize = 0;
  let pmRam = 0;
  if (isProcessMiningEnabled) {
    let pmProduct = formData.products.filter(p => p.key == constants.PROCESS_MINING_PRODUCT_OPTION.key)[0];
    if (pmProduct.properties != undefined) {
      let base = pmProduct.properties["eventsInMillions"] * pmProduct.properties["caseAndEventFields"] * 0.012;
      pmDiskSpace = Math.ceil(base * 12);
      pmTempDbSize = Math.ceil(base * 3);
      pmRam = Math.ceil((base * 2) + 16);
    }
  }

  const pmNode: NodeDataType = {
    nodeType: pmType,
    nodeTypeTooltip: pmTypeTooltip,
    nodeCount: "1",
    cpu: "16 vCPU (Minimum: 8 vCPU)",
    ram: Math.max(pmRam, 32) + " GiB",
    drives: [
      Math.max(pmDiskSpace, 256) + " GiB Disk Space",
      Math.max(pmTempDbSize, 128) + " GiB TempDb Database Size"
    ],
  };

  let details = new Array<string>();
  let dataDisk = "512 GiB SSD";



  // Single-node Evaluation Only
  if (profile === Profile.EvaluationOnly) {
    details.push("## Server Node")
    details.push("The [Single-node Evaluation Only](https://docs.uipath.com/automation-suite/docs/single-node-evaluation-profile-requirements-and-installation) profile only supports [2 server node configurations](https://docs.uipath.com/automation-suite/docs/single-node-evaluation-profile-requirements-and-installation#hardware-requirements): **16 vCPU / 32 GiB** and **32 vCPU / 64 GiB**.")

    let cpu = (onlyCoreProducts ? 16 : 32) * 1000;
    let ram = (onlyCoreProducts ? 32 : 64) * 1024 * 1024 * 1024;
    if (onlyCoreProducts) {
      details.push(`In current case, only core products are selected so a single **${formatCpu(cpu)}** / **${formatBytes(ram)}** server node is required.`)
      details.push(`Or you can substitute it with one server node and one agent node of **${formatCpu(cpu/2)}** / **${formatBytes(ram/2)}** each.`)
    } else {
      details.push(`In current case, because ${formData.products.filter(p => !p.isCoreProduct).map(p => "**" + p.name + "**").join(", ")} ${formData.products.filter(p => !p.isCoreProduct).length > 1 ? "are" : "is"} selected, a single **${formatCpu(cpu)}** / **${formatBytes(ram)}** server node is required.`)
      details.push(`Or you can substitute it with one server node and three agent nodes of **${formatCpu(cpu/4)}** / **${formatBytes(ram/4)}** each.`)
    }

    let nodes = new Array<NodeDataType>();
    let sqlNodes = new Array<NodeDataType>()
    nodes.push({
      nodeType: serverType,
      nodeTypeTooltip: serverTypeTooltip,
      nodeCount: "1",
      cpu: formatCpu(cpu),
      ram: formatBytes(ram),
      drives: [
        "16 GiB SSD",
        osDisk,
        dataDisk,
      ],
    });


    if (isTaskMiningEnabled || duGpuCount > 0 || isAdditionalNodeNeededEval) {
      details.push("## Dedicated Nodes")
    }

    if (isTaskMiningEnabled) {
      nodes.push(tmNode);
      details.push(`* **Task Mining** needs a [dedicated agent](https://docs.uipath.com/automation-suite/docs/adding-a-dedicated-agent-node-for-task-mining) node.`)
    }
    if (duGpuCount > 0) {
      nodes.push(duNode(duGpuCount))
      if (retrainingRequired) {
        details.push(`* **Document Understanding** needs a [dedicated agent](https://docs.uipath.com/automation-suite/docs/adding-a-dedicated-agent-node-with-gpu-support) node for **retraining**.`)
      }
      if (ocrGpuRequired) {
        details.push(`* **Document Understanding** needs a [dedicated agent](https://docs.uipath.com/automation-suite/docs/adding-a-dedicated-agent-node-with-gpu-support) node to handle more than **60 OCR pages per hour**.`)
      }
    }
    if (isAdditionalNodeNeededEval) {
      nodes.push({
        nodeType: asRobotsType,
        nodeTypeTooltip: asRobotsTypeTooltip,
        nodeCount: "1",
        cpu: formatCpu(asRobotNodeCpu),
        ram: formatBytes(asRobotNodeRam),
        drives: [
          "256 GiB SSD",
          asRobotNodeDisk + " GiB SSD"
        ],
      });
      details.push(`* **Automation Suite Robots** needs a [dedicated agent](https://docs.uipath.com/automation-suite/v2022.10/docs/hardware-and-software-requirements#additional-automation-suite-robots-requirements) node.`)
    }

    if (isProcessMiningEnabled) {
      sqlNodes.push(pmNode);
      details.push("## Separated SQL Server")
      details.push(`* **Process Mining** needs a [separate Microsoft SQL Server](https://docs.uipath.com/automation-suite/v2022.10/docs/multi-node-configuring-ms-sql-server#sql-requirements-for-process-mining).`)
    }

    // SQL Server node for Products SQL requirements
    sqlNodes.push({
      nodeType: sqlType,
      nodeTypeTooltip: sqlTypeTooltip,
      nodeCount: "1",
      cpu: "8 vCPU",
      ram: "32 GiB",
      drives: [
        "256 GiB SSD",
      ],
    });


    details.push("### Each Server Node Configuration")
    details.push(`* Requires **${formatCpu(cpu)}** processors.`)
    details.push(`* Requires **${formatBytes(ram)}** RAM.`)
    details.push(`* Requires **16 GiB SSD** Disk dedicated for storing **etcd** data.`)
    details.push(`* Requires **${osDisk}** Disk dedicated for storing **cluster state** data.`)
    details.push(`* Requires **${dataDisk}** Disk dedicated for storing **block** data.`)
    if (formData.highLevelParameters.host === "On-Prem" && !formData.highLevelParameters.externalS3) {
      nodes[0].drives.push("512 GiB SSD");
      details.push(`* Requires **512 GiB SSD** Disk dedicated for storing **objectstore** data.`)
    }
    if (formData.highLevelParameters.airGapped) {
      nodes[0].drives.push("512 GiB SSD");
      details.push(`* Requires **512 GiB SSD** Disk for temporarily unbundling the **offline** packages. This disk is only on the first server machine.`)
    }


    let recommendation : RecommendationDataType = {
      nodes: nodes,
      debug: details,
      sqlNodes: sqlNodes
    }

    if (formData.highLevelParameters.backupEnabled) {
      recommendation["nfsNode"] = nfsNode;
    }

    return recommendation;
  }

  // Multi-nodes Production, HA ready
  let totalCpu = 0;
  let totalRam = 0;
  let totalDataDisk = 0;
  let totalPvc = 0;

  let faultCount = +formData.highLevelParameters.faultCount;
  console.log("highLevelParameters:", formData.highLevelParameters);
  let serverCount = 3;

  details.push("## High Availability")
  if (faultCount == 0) {
    details.push("A node fault tolerance of **0** doesn't mean the cluster won't survive one node going down. The cluster will still be functional but it will be in a degraded state (some services might be unavailable or with reduced capacity) because the total capacity of the cluster without that missing node is less than the sum of the capacity needed by each component (see below).")
  } else {
    // Let X be the number of server nodes and F be the number of faults.
    // To survive F faults, the quorum (X/2+1) needs to be guaranteed, so X should be greater or equal to 2*F+1
    serverCount = Math.max(3, 2 * faultCount + 1);
    details.push(`To survive **${faultCount}** nodes going down:`);
    details.push(`* The cluster will need **${serverCount}** server nodes because in the worst case, these **${faultCount}** nodes will be server nodes which need to maintain a quorum of **${Math.floor(serverCount / 2) + 1}**.`);
    details.push(`* With **S** servers and **A** agent nodes, **S+A-${faultCount}** nodes need to have enough capacity to accomodate the resource requirements (see below).`)
    details.push(`* If dedicated nodes need to also be highly available, then they should also increase their count by **${faultCount}**. That being said, typically these node run background jobs, so real HA is not required.`)
  }


  details.push("## Resource Requirements")
  details.push("### Per Service")

  for (let product of formData.products) {
    let product_reqs = static_reqs.get(product.key)?.get(profile);
    totalCpu += product_reqs?.cpu ?? 0;
    totalRam += product_reqs?.ram ?? 0;
    //totalDataDisk += product_reqs?.dataDisk ?? 0;

    if (product.properties === undefined) {
      continue;
    }

    details.push(`#### ${product.name}`);
    details.push(`* Needs **${formatCpu(product_reqs?.cpu ?? 0, 1)}** / **${formatBytes((product_reqs?.ram ?? 0) * 1024 * 1024, 1)}** for the minimum number of replicas.`);

    console.log("Product:", product.name, ", Total CPU: ", totalCpu)
    switch (product.key) {
      case constants.ORCHESTRATOR_PRODUCT_OPTION.key:
        {
          let cpuDelta = 0;
          let ramDelta = 0;
          let concurrentRobots = +product.properties["concurrentRobots"];
          if (concurrentRobots <= 100) {
            // no-op
          } else if (concurrentRobots <= 1000) {
            cpuDelta += 4000;
            ramDelta += 8000;
            //totalDataDisk += 60
          } else if (concurrentRobots <= 10000) {
            cpuDelta += 15000;
            ramDelta += 16000;
            //totalDataDisk += 60;
          } else {
            cpuDelta += 75000;
            ramDelta += 22000;
            //totalDataDisk += 60;
          }
          details.push(additionalRequirements("Robots", concurrentRobots, cpuDelta, ramDelta));
          totalCpu += cpuDelta;
          totalRam += ramDelta;
          break;
        }
      case constants.PLATFORM_PRODUCT_OPTION.key:
        {
          {
            let cpuDelta = 0;
            let ramDelta = 0;
            let loginPerSecond = +product.properties["loginPerSecond"];
            if (loginPerSecond <= 5) {
              // no-op
            } else if (loginPerSecond <= 20) {
              cpuDelta = 1000;
              ramDelta = 1000;
            } else if (loginPerSecond <= 50) {
              cpuDelta = 3000;
              ramDelta = 12000;
            } else if (loginPerSecond <= 100) {
              cpuDelta = 9000;
              ramDelta = 20000;
            } else {
              cpuDelta = 16000;
              ramDelta = 30000;
            }
            details.push(additionalRequirements("Logins Per Second", loginPerSecond, cpuDelta, ramDelta));
            totalCpu += cpuDelta;
            totalRam += ramDelta;
          }

          {
            let cpuDelta = 0;
            let ramDelta = 0;
            let concurrentUsers = +product.properties["concurrentUsers"];
            if (concurrentUsers <= 10) {
              // no-op
            } else if (concurrentUsers <= 25) {
              cpuDelta = 1000;
              ramDelta = 1500;
            } else if (concurrentUsers <= 50) {
              cpuDelta = 3000;
              ramDelta = 4500;
            } else if (concurrentUsers <= 100) {
              cpuDelta = 5000;
              ramDelta = 6500;
            } else if (concurrentUsers <= 150) {
              cpuDelta = 7000;
              ramDelta = 8500;
            } else {
              cpuDelta = 10000;
              ramDelta = 12000;
            }
            details.push(additionalRequirements("Concurrent Users", concurrentUsers, cpuDelta, ramDelta));
            totalCpu += cpuDelta;
            totalRam += ramDelta;
          }
          break;
        }
      case constants.AUTOMATION_OPS_PRODUCT_OPTION.key:
        {
          let cpuDelta = 0;
          let ramDelta = 0;
          let concurrentUsers = +product.properties["concurrentUsers"];
          if (concurrentUsers <= 10) {
            // no-op
          } else if (concurrentUsers <= 55) {
            cpuDelta = 1500;
            ramDelta = 1500;
          } else {
            cpuDelta = 4000;
            ramDelta = 2000;
          }
          details.push(additionalRequirements("Concurrent Users", concurrentUsers, cpuDelta, ramDelta));
          totalCpu += cpuDelta;
          totalRam += ramDelta;
          break;
        }
      case constants.AUTOMATION_HUB_PRODUCT_OPTION.key:
        {
          let cpuDelta = 0;
          let ramDelta = 0;
          let concurrentUsers = +product.properties["concurrentUsers"];
          if (concurrentUsers <= 10) {
            // no-op
          } else if (concurrentUsers <= 20) {
            cpuDelta = 1000;
            ramDelta = 2000;
          } else {
            cpuDelta = 7000;
            ramDelta = 4000;
          }
          details.push(additionalRequirements("Concurrent Users", concurrentUsers, cpuDelta, ramDelta));
          totalCpu += cpuDelta;
          totalRam += ramDelta;
          break;
        }
      case constants.DOCUMENT_UNDERSTANDING_PRODUCT_OPTION.key:
        {
          {
            let generalExtractorPerHour = +product.properties["generalExtractorPerHour"];
            if (generalExtractorPerHour > 0) {
              let cpuDelta = 1000 * Math.ceil(12 * generalExtractorPerHour / 3600);
              let ramDelta = 4 * 1024 * Math.ceil(12 * generalExtractorPerHour / 3600);
              details.push(additionalRequirements("General Extractor (pages per hour)", generalExtractorPerHour, cpuDelta, ramDelta));
              totalCpu += cpuDelta;
              totalRam += ramDelta;
            }
          }

          {
            let invoicesPerHour = +product.properties["invoicesPerHour"];
            if (invoicesPerHour > 0) {
              let cpuDelta = 1000 * Math.ceil(5 * invoicesPerHour / 3600);
              let ramDelta = 4 * 1024 * Math.ceil(5 * invoicesPerHour / 3600);
              details.push(additionalRequirements("Invoices (pages per hour)", invoicesPerHour, cpuDelta, ramDelta));
              totalCpu += cpuDelta;
              totalRam += ramDelta;
            }
          }

          {
            let receiptsPerHour = +product.properties["receiptsPerHour"];
            if (receiptsPerHour > 0) {
              let cpuDelta = 1000 * Math.ceil(12 * receiptsPerHour / 3600);
              let ramDelta = 4 * 1024 * Math.ceil(12 * receiptsPerHour / 3600);
              details.push(additionalRequirements("Receipts (pages per hour)", receiptsPerHour, cpuDelta, ramDelta));
              totalCpu += cpuDelta;
              totalRam += ramDelta;
            }
          }

          {
            let purchaseOrdersPerHour = +product.properties["purchaseOrdersPerHour"];
            if (purchaseOrdersPerHour > 0) {
              let cpuDelta = 1000 * Math.ceil(12 * purchaseOrdersPerHour / 3600);
              let ramDelta = 4 * 1024 * Math.ceil(12 * purchaseOrdersPerHour / 3600);
              details.push(additionalRequirements("Purchase Order (pages per hour)", purchaseOrdersPerHour, cpuDelta, ramDelta));
              totalCpu += cpuDelta;
              totalRam += ramDelta;
            }
          }

          {
            let utilityBillsPerHour = +product.properties["utilityBillsPerHour"];
            if (utilityBillsPerHour > 0) {
              let cpuDelta = 1000 * Math.ceil(12 * utilityBillsPerHour / 3600);
              let ramDelta = 4 * 1024 * Math.ceil(12 * utilityBillsPerHour / 3600);
              details.push(additionalRequirements("Utility Bills (pages per hour)", utilityBillsPerHour, cpuDelta, ramDelta));
              totalCpu += cpuDelta;
              totalRam += ramDelta;
            }
          }

          {
            let ocrPerHour = +product.properties["ocrPerHour"];
            if (ocrPerHour > 0) {
              let cpuDelta = 4000 * Math.ceil(2 * ocrPerHour / 3600);
              let ramDelta = 8 * 1024 * Math.ceil(2 * ocrPerHour / 3600);
              details.push(additionalRequirements("OCR (pages per hour)", ocrPerHour, cpuDelta, ramDelta));
              totalCpu += cpuDelta;
              totalRam += ramDelta;
            }
          }

          {
            let shredsPerHour = +product.properties["shredsPerHour"];
            if (shredsPerHour > 0) {
              let cpuDelta = 1000 * Math.ceil(shredsPerHour / 1500.0);
              console.log(cpuDelta, shredsPerHour)
              let ramDelta = shredsPerHour > 0 ? 6 * 1024 : 0;
              details.push(additionalRequirements("Intelligent Form Extractor (handwriting shreds per hour)", shredsPerHour, cpuDelta, ramDelta));
              totalCpu += cpuDelta;
              totalRam += ramDelta;
            }
          }

          break;
        }
      case constants.ACTION_CENTER_PRODUCT_OPTION.key:
        {
          let cpuDelta = 0;
          let ramDelta = 0;
          let concurrentUsers = +product.properties["concurrentUsers"];
          if (concurrentUsers <= 50) {
            // no-op
          } else if (concurrentUsers <= 100) {
            cpuDelta = 200;
            ramDelta = 500;
          } else if (concurrentUsers <= 500) {
            cpuDelta = 600;
            ramDelta = 1500;
          } else {
            cpuDelta = 1200;
            ramDelta = 3000;
          }
          details.push(additionalRequirements("Concurrent Users", concurrentUsers, cpuDelta, ramDelta));
          totalCpu += cpuDelta;
          totalRam += ramDelta;
          break;
        }
      case constants.TEST_MANAGER_PRODUCT_OPTION.key:
        {
          let cpuDelta = 0;
          let ramDelta = 0;
          let concurrentUsers = +product.properties["concurrentUsers"];
          if (concurrentUsers <= 100) {
            // no-op
          } else if (concurrentUsers <= 300) {
            cpuDelta = 400;
            ramDelta = 900;
          } else {
            cpuDelta = 800;
            ramDelta = 1800;
          }
          details.push(additionalRequirements("Concurrent Users", concurrentUsers, cpuDelta, ramDelta));
          totalCpu += cpuDelta;
          totalRam += ramDelta;
          break;
        }
      case constants.AI_CENTER_PRODUCT_OPTION.key:
        {
          let cpuDelta = 0;
          let ramDelta = 0;
          let numberOfSkills = +product.properties["numberOfSkills"];
          if (numberOfSkills > 0) {
            cpuDelta = 1000 * numberOfSkills;
            ramDelta = 4 * 1024 * numberOfSkills;
            details.push(additionalRequirements("Skills", numberOfSkills, cpuDelta, ramDelta));
            totalCpu += cpuDelta;
            totalRam += ramDelta;
          }

          let numberOfPipelines = +product.properties["numberOfPipelines"];
          if (numberOfPipelines > 0) {
            cpuDelta = 1000 * numberOfPipelines;
            ramDelta = 12 * 1024 * numberOfPipelines;
            let propName = "Concurrent Pipelines";
            details.push(additionalRequirements(propName, numberOfPipelines, cpuDelta, ramDelta));
            totalCpu += cpuDelta;
            totalRam += ramDelta;
            totalPvc += 50 * numberOfPipelines;
            details.push(`* Handling **${numberOfPipelines}** of **${propName}** requires an additional **${totalPvc}** GiB PVC.`);
          }
        }
    }
  }

  // Considering Fabric a seperate product from Shared Suite Capabilities
  let fabric_reqs = static_reqs.get("fabric")?.get(profile);
  totalCpu += fabric_reqs?.cpu ?? 0;
  totalRam += fabric_reqs?.ram ?? 0;

  details.push(`#### Fabric`);
  details.push(`* Needs **${formatCpu(fabric_reqs?.cpu ?? 0, 1)}** / **${formatBytes((fabric_reqs?.ram ?? 0) * 1024 * 1024, 1)}** for the minimum number of replicas.`);


  details.push("### Total");
  details.push("Service resource requirements:");
  details.push(`* Actual: **${formatCpu(totalCpu)}** / **${formatBytes(totalRam * 1024 * 1024)}**`);


  details.push("### Per Node");
  details.push("Not all the CPU / RAM of the nodes is available to run the services:")
  details.push("* Each node reserves **0.6 vCPU** / **2 GiB** for the OS.");
  details.push("* Each node has static components (logging, metrics, etc...) reserving **0.6 vCPU** / **270 MiB**.")

  let nodePhysicalCpu = formData.highLevelParameters.coreCount * 1000;
  let nodePhysicalRam = formData.highLevelParameters.coreCount * 2 * 1024 * 1024 * 1024;
  let nodeAllocatableCpu = nodePhysicalCpu - 600;
  let nodeAllocatableRam = nodePhysicalRam - 2 * 1024 * 1024 * 1024;
  let nodeAvailableCpu = nodeAllocatableCpu - 600;
  let nodeAvailableRam = nodeAllocatableRam - 270 * 1024 * 1024;
  let totalServerCPU = serverCount * nodeAvailableCpu;
  let totalAgentCPU = totalCpu - totalServerCPU;
  let minAgentNode = Math.ceil(totalAgentCPU / nodeAvailableCpu);

  // Resource requirements in case of multizonal cluster
  if (formData.highLevelParameters.isMultizoanl) {
    let minCount = Math.ceil(Math.max(serverCount + minAgentNode, totalRam / nodeAvailableRam));
    let nodeCount = Math.max(serverCount, minCount + faultCount);
    let agentCount = Math.max(0, nodeCount - serverCount);
    const zoneFailureDelta = getMultizonalFactor(nodeCount);
    totalCpu *= zoneFailureDelta;
    totalRam *= zoneFailureDelta;
  }

  // Add some breathing room
  totalCpu *= 1.20;
  totalRam *= 1.20;
  details.push(`* With 20% buffer: **${formatCpu(totalCpu)}** / **${formatBytes(totalRam * 1024 * 1024)}**`);
  if (totalPvc > 0) {
    details.push(`* Handling AI Center pipelines requires an additional **${totalPvc}** GiB PVC.`);
  }

  totalAgentCPU = totalCpu - totalServerCPU;
  minAgentNode = Math.ceil(totalAgentCPU / nodeAvailableCpu);

  let minCount = Math.ceil(Math.max(serverCount + minAgentNode, totalRam / nodeAvailableRam));
  let nodeCount = Math.max(serverCount, minCount + faultCount);
  let agentCount = Math.max(0, nodeCount - serverCount);
  

  details.push("## Recommendation");
  details.push(`* A **${formatCpu(nodePhysicalCpu)}** / **${formatBytes(nodePhysicalRam)}** has an *allocatable* capacity of **${formatCpu(nodeAllocatableCpu)}** / **${formatBytes(nodeAllocatableRam)}**, of which only **${formatCpu(nodeAvailableCpu)}** / **${formatBytes(nodeAvailableRam)}** is *available* for a node.`);
  details.push(`* Therefore, to schedule **${formatCpu(totalCpu)}** / **${formatBytes(totalRam * 1024 * 1024)}** worth of services, at least **${minCount}** nodes are required.`);
  details.push(`* But to survive **${faultCount}** node failures, **${minCount} + ${faultCount} = ${minCount + faultCount}** nodes are required.`)
  details.push(`* Out of which, **${serverCount}** server nodes and, **${agentCount}** agent nodes are required.`)

  switch (formData.highLevelParameters.host) {
    case "AWS":
      details.push(`* You will need a shared **SQL server** and **AWS S3 Buckets** for the selected products.`);
      break;
    case "Azure":
      details.push(`* You will need a shared **SQL server** and **Azure Blob Storage** for the selected products.`);
      break;
    case "On-Prem":
      if (formData.highLevelParameters.externalS3) {
        details.push(`* You will need a shared **SQL server** and **S3 Compatible Object Store** for the selected products.`);
      }
      else {
        details.push(`* You will need a shared **SQL server** for the selected products.`);
      }
      break;
  }

  // Rounding up
  totalDataDisk = Math.ceil(totalDataDisk);

  let nodes = new Array<NodeDataType>();
  let sqlNodes = new Array<NodeDataType>();
  nodes.push({
    nodeType: serverType,
    nodeTypeTooltip: serverTypeTooltip,
    nodeCount: serverCount.toString(),
    nodeCountTooltip: `An odd number of Server nodes. At least ${serverCount}.`,
    cpu: formatCpu(nodePhysicalCpu),
    ram: formatBytes(nodePhysicalRam),
    drives: [
      "16 GiB SSD",
      osDisk,
      dataDisk,
    ],
  });


  if (agentCount > 0) {
    nodes.push({
      nodeType: agentType,
      nodeTypeTooltip: agentTypeTooltip,
      nodeCount: agentCount.toString(),
      nodeCountTooltip: `See details below.`,
      cpu: formatCpu(nodePhysicalCpu),
      ram: formatBytes(nodePhysicalRam),
      drives: [
        "256 GiB SSD",
      ],
    });
  }

  if (isTaskMiningEnabled) {
    nodes.push(tmNode);
    details.push(`* **Task Mining** needs a dedicated node.`)
  }
  if (duGpuCount > 0) {
    nodes.push(duNode(duGpuCount))
    details.push(`* **Document Understanding** needs a dedicated node.`)
  }
  if (isAutomationSuiteRobotsEnabled) {
    nodes.push({
      nodeType: asRobotsType,
      nodeTypeTooltip: asRobotsTypeTooltip,
      nodeCount: "1",
      cpu: Math.max(formData.highLevelParameters.coreCount,parseFloat((asRobotNodeCpu / 1000.0).toFixed(1))) + " vCPU",
      ram: formatBytes(asRobotNodeRam),
      drives: [
        "256 GiB SSD",
        asRobotNodeDisk + " GiB SSD"
      ],
    });
    details.push(`* **Automation Suite Robots** needs a dedicated node.`)
  }

  if (isProcessMiningEnabled) {
    sqlNodes.push(pmNode);
    details.push(`* **Process Mining** needs a [separate Microsoft SQL Server](https://docs.uipath.com/automation-suite/v2022.10/docs/multi-node-configuring-ms-sql-server#sql-requirements-for-process-mining).`)
  }

  // SQL Server node for Products SQL requirements
  sqlNodes.push({
    nodeType: sqlType,
    nodeTypeTooltip: sqlTypeTooltip,
    nodeCount: "1",
    cpu: "8 vCPU",
    ram: "32 GiB",
    drives: [
      "256 GiB SSD",
    ],
  });

  let recommendation : RecommendationDataType = {
    nodes: nodes,
    debug: details,
    sqlNodes: sqlNodes
  }

  if (formData.highLevelParameters.backupEnabled) {
    recommendation["nfsNode"] = nfsNode;
    details.push(`* **Enabling Backup** will need an NFS Server or equivalent PaaS file share.`)
  }

  details.push("### Each Server Node Configuration")
  details.push(`* Requires **${formatCpu(nodePhysicalCpu)}** processors.`);
  details.push(`* Requires **${formatBytes(nodePhysicalRam)}** RAM.`);
  details.push(`* Requires **16 GiB SSD** Disk dedicated for storing **etcd** data.`);
  details.push(`* Requires **${osDisk}** Disk dedicated for storing **cluster state** data.`);
  details.push(`* Requires **${dataDisk}** Disk dedicated for storing **block** data.`);
  if (formData.highLevelParameters.host === "On-Prem" && !formData.highLevelParameters.externalS3) {
    nodes[0].drives.push("512 GiB SSD");
    details.push(`* Requires **512 GiB SSD** Disk dedicated for storing **objectstore** data.`);
  }
  if (formData.highLevelParameters.airGapped) {
    nodes[0].drives.push("512 GiB SSD");
    details.push(`* Requires **512 GiB SSD** Disk for temporarily unbundling the **offline** packages. This disk is only on the first server machine.`);
  }


  if (agentCount > 0) {
    details.push("### Each Agent Node Configuration")
    details.push(`* Requires **${formatCpu(nodePhysicalCpu)}** processors.`)
    details.push(`* Requires **${formatBytes(nodePhysicalRam)}** RAM.`)
    details.push(`* Requires **256 GiB SSD** Disk dedicated for storing **cluster state** data.`)
  }

  details.push(`> **etcd**, **block**, and **objectstore** disk must be a dedicated disk on each server node, the rest can be merged together or even with the operating system disk.`)
  
  return recommendation;
};
