import React from "react";
import {
  getNumberInputValue,
  getNumberOrUndefinedFromString,
} from "../../../../utils/inputUtils";
import InputTextApollo from "../../../inputs/InputTextApollo";
import ProductWidgetContainer from "./ProductWidgetContainer";
import styled from "styled-components/macro";

const FlexSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const ProcessMiningProductWidget = ({
  product,
  onRemove,
  onProductChange,
  showDetails,
}: any) => {

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (product?.properties && name) {
      const productCopy = { ...product };
      productCopy.properties = { ...productCopy.properties };
      productCopy.properties[name] = getNumberOrUndefinedFromString(value);
      onProductChange(productCopy);
    }
  };

  return (
    <ProductWidgetContainer
      onRemove={onRemove}
      title={product.name}
      productKey={product.key}
      canBeRemoved={product.canBeRemoved}
      showDetails={showDetails}
    >
      <FlexSection>
        <InputTextApollo
          label="Number of Events In Millions"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "eventsInMillions",
            value: getNumberInputValue(product.properties.eventsInMillions),
            onChange: handleInputChange,
          }}
        />
        <InputTextApollo
          label="Number of Case And Events Fields"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "caseAndEventFields",
            value: getNumberInputValue(product.properties.caseAndEventFields),
            onChange: handleInputChange,
          }}
        />
      </FlexSection>
    </ProductWidgetContainer>
  );
};

export default ProcessMiningProductWidget;
