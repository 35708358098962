import React from "react";
import {
  getNumberInputValue,
  getNumberOrUndefinedFromString,
} from "../../../../utils/inputUtils";
import InputTextApollo from "../../../inputs/InputTextApollo";
import ProductWidgetContainer from "./ProductWidgetContainer";
import styled from "styled-components/macro";

const FlexSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const PlatformProductWidget = ({
  product,
  onRemove,
  onProductChange,
  showDetails,
  canBeRemoved,
}: any) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (product?.properties && name) {
      const productCopy = { ...product };
      productCopy.properties = { ...productCopy.properties };
      productCopy.properties[name] = getNumberOrUndefinedFromString(value);
      onProductChange(productCopy);
    }
  };

  return (
    <ProductWidgetContainer
      onRemove={onRemove}
      title={product.name}
      productKey={product.key}
      canBeRemoved={canBeRemoved}
      showDetails={showDetails}
    >
      <FlexSection>
        <div>
          <InputTextApollo
            label="Login Per Second"
            variant="outlined"
            inputProps={{ type: "number" }}
            InputProps={{
              name: "loginPerSecond",
              value: getNumberInputValue(product.properties.loginPerSecond),
              onChange: handleInputChange,
            }}
          />
        </div>
          <div>
          <InputTextApollo
            label="Concurrent Users"
            variant="outlined"
            inputProps={{ type: "number" }}
            InputProps={{
              name: "concurrentUsers",
              value: getNumberInputValue(product.properties.concurrentUsers),
              onChange: handleInputChange,
            }}
          />
          </div>
      </FlexSection>
    </ProductWidgetContainer>
  );
};

export default PlatformProductWidget;
