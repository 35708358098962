import ApolloThemeProvider from "./components/ApolloThemeProvider/ApolloThemeProvider";
import CalculatorForm from "./components/CalculatorForm/CalculatorForm";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <ApolloThemeProvider>
      <Router>
        <CalculatorForm />
      </Router>
    </ApolloThemeProvider>
  );
}

export default App;
