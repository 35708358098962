import React from "react";
import ProductWidgetContainer from "./ProductWidgetContainer";
import styled from "styled-components/macro";
import {
  getNumberInputValue,
  getNumberOrUndefinedFromString,
} from "../../../../utils/inputUtils";
import InputTextApollo from "../../../inputs/InputTextApollo";
import { Checkbox, FormControlLabel} from "@material-ui/core";

const FlexSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const JobBasedProductWidget = ({
  product,
  onRemove,
  onProductChange,
  showDetails,
  canBeRemoved,
}: {
  product: ProductOptionInterface;
  onRemove: () => void;
  onProductChange: (updatedProduct: ProductOptionInterface) => void;
  defaultConcurrentUsers?: number;
  showDetails: boolean;
  canBeRemoved: 0 | 1;
}) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    if (product?.properties && name) {
      const productCopy = { ...product };
      productCopy.properties = { ...productCopy.properties };
      productCopy.properties[name] = !productCopy.properties[name];
      onProductChange(productCopy);
    }
  };

  const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e?.currentTarget;
    if (product?.properties && name) {
      const productCopy = { ...product };
      productCopy.properties = { ...productCopy.properties };
      productCopy.properties[name] = getNumberOrUndefinedFromString(value);
      onProductChange(productCopy);
    }
  };

  return (
    <ProductWidgetContainer
      onRemove={onRemove}
      title={product.name}
      productKey={product.key}
      canBeRemoved={canBeRemoved}
      showDetails={showDetails}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={!!product?.properties?.externalOrchestrator}
            onChange={handleCheckboxChange}
            name="externalOrchestrator"
          />
        }
        label="Connect to external Orchestrator"
      />
      <FlexSection>
        <InputTextApollo
          label="Number of Skills"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "numberOfSkills",
            value: getNumberInputValue(product?.properties?.numberOfSkills || ""),
            onChange: handleNumberChange,
          }}
        />
        <InputTextApollo
          label="Number of Concurrent Pipelines"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "numberOfPipelines",
            value: getNumberInputValue(product?.properties?.numberOfPipelines || ""),
            onChange: handleNumberChange,
          }}
        />
      </FlexSection>
    </ProductWidgetContainer>
  );
};

export default JobBasedProductWidget;
