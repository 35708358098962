export const getNumberInputValue = (value: number | undefined) =>
  value === undefined || value === null ? "" : value.toString();

export const getBooleanInputValue = (value: boolean | undefined) =>
  value === undefined || value === null ? false : true;

export const getNumberOrUndefinedFromString = (value: string) => {
  if (value === "") {
    return undefined;
  } else if (typeof Number(value) === "number") {
    let number = Number(value);
    if (number < 0) {
      return 0;
    }
    return number;
  } else {
    return undefined;
  }
};

// Returns boolean of success status
export const copyTextToClipboard = (text: string): boolean => {
  if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    const textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed";
    textarea.style.opacity = "0";
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy");
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
  return false;
};
