import React, { useEffect } from "react";
import {
  getNumberInputValue,
  getNumberOrUndefinedFromString,
} from "../../../../utils/inputUtils";
import InputTextApollo from "../../../inputs/InputTextApollo";
import ProductWidgetContainer from "./ProductWidgetContainer";

const GenericUsersProductWidget = ({
  product,
  onRemove,
  onProductChange,
  defaultConcurrentUsers,
  showDetails,
}: {
  product: ProductOptionInterface;
  onRemove: () => void;
  onProductChange: (updatedProduct: ProductOptionInterface) => void;
  defaultConcurrentUsers?: number;
  showDetails: boolean;
}) => {
  useEffect(() => {
    if (typeof defaultConcurrentUsers === "number") {
      const productCopy = { ...product };
      productCopy.properties = { ...productCopy.properties };
      productCopy.properties.concurrentUsers = defaultConcurrentUsers;
      onProductChange(productCopy);
    }
    // Only want this on mount
    // eslint-disable-next-line
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e?.currentTarget || {};
    if (product?.properties && name) {
      const productCopy = { ...product };
      productCopy.properties = { ...productCopy.properties };
      productCopy.properties[name] = getNumberOrUndefinedFromString(value);
      onProductChange(productCopy);
    }
  };

  return (
    <ProductWidgetContainer
      onRemove={onRemove}
      title={product?.name}
      productKey={product.key}
      canBeRemoved={product?.canBeRemoved}
      showDetails={showDetails}
    >
      <InputTextApollo
        label="Concurrent Users"
        variant="outlined"
        inputProps={{ type: "number" }}
        InputProps={{
          name: "concurrentUsers",
          value: getNumberInputValue(
            product?.properties?.concurrentUsers || ""
          ),
          onChange: handleInputChange,
        }}
      />
    </ProductWidgetContainer>
  );
};

export default GenericUsersProductWidget;
