import React from "react";
import { Checkbox, FormControlLabel } from "@material-ui/core";
import {
  getNumberInputValue,
  getNumberOrUndefinedFromString,
} from "../../../../utils/inputUtils";
import InputTextApollo from "../../../inputs/InputTextApollo";
import ProductWidgetContainer from "./ProductWidgetContainer";
import styled from "styled-components/macro";

const FlexSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const DocumentUnderstandingWidget = ({
  product,
  onRemove,
  onProductChange,
  showDetails,
}: any) => {
  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name } = e.currentTarget;
    const productCopy = { ...product };
    productCopy.properties = { ...productCopy.properties };
    productCopy.properties[name] = !productCopy.properties[name];
    onProductChange(productCopy);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (product?.properties && name) {
      const productCopy = { ...product };
      productCopy.properties = { ...productCopy.properties };
      productCopy.properties[name] = getNumberOrUndefinedFromString(value);
      onProductChange(productCopy);
    }
  };

  return (
    <ProductWidgetContainer
      onRemove={onRemove}
      title={product.name}
      productKey={product.key}
      canBeRemoved={product.canBeRemoved}
      showDetails={showDetails}
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={!!product.properties.retrainingRequired}
            onChange={handleCheckboxChange}
            name="retrainingRequired"
          />
        }
        label="Retraining Required"
      />
      <FlexSection>
        <InputTextApollo
          label="General Extractor (pages per hour)"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "generalExtractorPerHour",
            value: getNumberInputValue(product.properties.generalExtractorPerHour),
            onChange: handleInputChange,
          }}
        />
        <InputTextApollo
          label="Invoices (pages per hour)"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "invoicesPerHour",
            value: getNumberInputValue(product.properties.invoicesPerHour),
            onChange: handleInputChange,
          }}
        />
        <InputTextApollo
          label="Receipts (pages per hour)"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "receiptsPerHour",
            value: getNumberInputValue(product.properties.receiptsPerHour),
            onChange: handleInputChange,
          }}
        />
        <InputTextApollo
          label="Purchase Orders (pages per hour)"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "purchaseOrdersPerHour",
            value: getNumberInputValue(product.properties.purchaseOrdersPerHour),
            onChange: handleInputChange,
          }}
        />
        <InputTextApollo
          label="Utility Bills (pages per hour)"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "utilityBillsPerHour",
            value: getNumberInputValue(product.properties.utilityBillsPerHour),
            onChange: handleInputChange,
          }}
        />
        <InputTextApollo
          label="OCR (pages per hour)"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "ocrPerHour",
            value: getNumberInputValue(product.properties.ocrPerHour),
            onChange: handleInputChange,
          }}
        />
        <InputTextApollo
          label="Intelligent Form Extractor (handwriting shreds per hour)"
          variant="outlined"
          inputProps={{ type: "number" }}
          InputProps={{
            name: "shredsPerHour",
            value: getNumberInputValue(product.properties.shredsPerHour),
            onChange: handleInputChange,
          }}
        />
      </FlexSection>
    </ProductWidgetContainer>
  );
};

export default DocumentUnderstandingWidget;
