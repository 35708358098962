import { createGlobalStyle, css } from "styled-components/macro";
import Tokens from "@uipath/apollo-core";

// @ts-ignore
export const GlobalBaseStyles = createGlobalStyle((props: any) => {
  const fontFamily = Tokens.FontFamily.FontNormal;
  return css`
    *,
    *::before,
    *::after {
      box-sizing: border-box;
      margin: 0px;
      padding: 0px;
      font-family: ${fontFamily};
    }
    /* Always show the scroll bar on Mac*/
    *::-webkit-scrollbar {
      -webkit-appearance: none;
    }
    *::-webkit-scrollbar:vertical {
      width: 11px;
    }
    *::-webkit-scrollbar:horizontal {
      height: 11px;
    }
    *::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid
        ${props?.theme?.apolloTheme?.palette?.semantic?.colorBackground};
      background-color: ${props?.theme?.apolloTheme?.palette?.semantic
        ?.colorBackgroundGray};
    }
    *::-webkit-scrollbar-track,
    *::-webkit-scrollbar-corner {
      background: ${props?.theme?.apolloTheme?.palette?.semantic
        ?.colorBackground || props?.theme?.colors?.gray300};
    }
    html {
      font-family: ${fontFamily};
      min-height: 100vh;
      color: ${props?.theme?.apolloTheme?.palette?.semantic
        ?.colorForegroundDeEmp || "black"};
      background: ${props?.theme?.apolloTheme?.palette?.semantic
        ?.colorBackground || "white"};
    }
    body {
      margin: 0;
      padding: 0;
      font-family: ${fontFamily};
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 16px;
      color: ${props?.theme?.apolloTheme?.palette?.semantic
        ?.colorForegroundDeEmp || "black"};
    }
    a {
      color: inherit;
      text-decoration: none;
    }
    button {
      border: none;
      color: ${props?.theme?.apolloTheme?.palette?.semantic?.colorForeground ||
      "black"};
      background: ${props?.theme?.apolloTheme?.palette?.semantic
        ?.colorBackground || props?.theme?.colors?.white};
      cursor: pointer;
    }
    ul,
    ol {
      list-style: none;
    }
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
    }
    textarea,
    input {
      font-family: ${fontFamily};
    }
    body:not(.user-is-tabbing) button:focus,
    body:not(.user-is-tabbing) input:focus,
    body:not(.user-is-tabbing) select:focus,
    body:not(.user-is-tabbing) textarea:focus {
      outline: none;
    }
  `;
});
