import React, {useEffect, useState} from "react";
import {useHistory, useLocation} from "react-router-dom";
import styled from "styled-components/macro";
import {DEFAULT_CONCURRENT_USERS_NUMBER, STARTING_PRODUCT_OPTIONS,} from "../../constants/calculatorConstants";
import {getDecodedParamsJsonOrNull} from "../../utils/sharableCalculatorUtils";
import Heading from "./Heading/Heading";
import AddProductSection from "./AddProductSection/AddProductSection";
import HighLevelConfig from "./HighLevelConfig/HighLevelConfig";
import RecommendationWidget from "./RecommendationWidget/RecommendationWidget";

const PageContainer = styled.div`
  min-height: 100vh;
  max-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  padding: 0 10px;
  display: grid;
  grid-template-columns: 4fr 3fr;
  grid-gap: 10px;
  align-items: flex-start;
  height: calc(100vh - 90px);

  @media (max-width: 900px) {
    display: block;
    height: unset;
  }
`;

const Container = styled.div`
  width: 100%;
  padding: 20px;
  margin: 20px auto;
  border: 1px solid var(--color-border);
  background: var(--color-background-secondary);
  border-radius: 3px;
  max-height: 100%;
  overflow: auto;
`;

const CalculatorForm = () => {
    const location = useLocation();
    const history = useHistory();

    const [formData, setFormData] = useState<CalculatorFormDataType>({
        highLevelParameters: {
            profile: "eval",
            airGapped: false,
            faultCount: 0,
            coreCount: 16,
            host: "AWS",
            externalS3: false,
            backupEnabled: false,
            isMultizoanl: false,
        },
        products: STARTING_PRODUCT_OPTIONS,
    });

    useEffect(() => {
        if (location.search) {
            const parsedData: any = getDecodedParamsJsonOrNull(location.search || "");
            if (parsedData) {
                setFormData(parsedData);
            }
            history.replace("");
        }
        // only for on mount
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeHighLevelParameters = (
        highLevelParameters: HighLevelParametersInterface
    ) => {
        const formDataCopy = {...formData};
        formDataCopy.highLevelParameters = highLevelParameters;
        setFormData(formDataCopy);
    };

    const handleSelectedProductsChange = (products: any) => {
        const formDataCopy = {...formData};
        formDataCopy.products = products;
        setFormData(formDataCopy);
    };

    return (
        <PageContainer>
            <Heading
                formData={formData}
                selectedProducts={formData.products}
                onSelectedProductsChange={handleSelectedProductsChange}
            />
            <Wrapper>
                <Container>
                    <HighLevelConfig
                        highLevelParameters={formData.highLevelParameters}
                        onChangeHighLevelParameters={handleChangeHighLevelParameters}
                    />
                    <RecommendationWidget formData={formData}/>
                </Container>
                <Container>
                    <AddProductSection
                        selectedProducts={formData.products}
                        onSelectedProductsChange={handleSelectedProductsChange}
                        defaultConcurrentUsers={DEFAULT_CONCURRENT_USERS_NUMBER}
                        showDetails={formData.highLevelParameters.profile === "ha"}
                    />
                </Container>
            </Wrapper>
        </PageContainer>
    );
};

export default CalculatorForm;
