import React, { useState } from "react";
import {
  apolloMaterialUiThemeLight,
  apolloMaterialUiThemeDark,
} from "@uipath/apollo-material-ui-theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { ThemeProvider as ScThemeProvider } from "styled-components";
import CssBaseline from "@material-ui/core/CssBaseline";
import { GlobalBaseStyles } from "./GlobalBaseStyles";

type ApolloThemeName = "dark" | "light";

export interface ApolloThemeProviderProps {
  children: React.ReactNode;
}

export interface ApolloThemeContextProps {
  currentTheme: ApolloThemeName;
  setTheme: React.Dispatch<React.SetStateAction<ApolloThemeName>> | null;
}

export const ApolloThemeContext = React.createContext<ApolloThemeContextProps>({
  currentTheme: "light",
  setTheme: null,
});

const getTheme = (name: ApolloThemeName) => {
  return name === "dark"
    ? apolloMaterialUiThemeDark
    : apolloMaterialUiThemeLight;
};

const ApolloThemeProvider = (props: ApolloThemeProviderProps) => {
  const { children } = props;
  const currentTheme = "light";
  const [themeName, setThemeName] = useState<ApolloThemeName>(currentTheme);
  const theme = getTheme(themeName);

  const contextValue = {
    currentTheme: themeName,
    setTheme: setThemeName,
  };

  return (
    <ApolloThemeContext.Provider value={contextValue}>
      <ScThemeProvider
        theme={{
          apolloTheme: theme,
        }}
      >
        {/* @ts-ignore */}
        <portal-sheet theme={themeName}>
          <CssBaseline />
          <GlobalBaseStyles />
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
          {/* @ts-ignore */}
        </portal-sheet>
      </ScThemeProvider>
    </ApolloThemeContext.Provider>
  );
};

export default ApolloThemeProvider;
