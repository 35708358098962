import React from "react";
import { Button, ButtonProps } from "@material-ui/core";

export const ButtonApolloPrimary = (props: ButtonProps) => {
  return (
    <Button variant="contained" disableElevation color="secondary" {...props} />
  );
};

export const ButtonApolloSecondary = (props: ButtonProps) => {
  return <Button variant="outlined" disableElevation {...props} />;
};

export const ButtonApolloText = (props: ButtonProps) => {
  return <Button color="secondary" {...props} />;
};
