import ProductWidgetContainer from "./ProductWidgets/ProductWidgetContainer";
import OrchestratorProductWidget from "./ProductWidgets/OrchestratorProductWidget";
import DocumentUnderstandingWidget from "./ProductWidgets/DocumentUnderstandingWidget";
import JobBasedProductWidget from "./ProductWidgets/JobBasedProductWidget";
import GenericUsersProductWidget from "./ProductWidgets/GenericUsersProductWidget";
import AutomationSuiteRobotsProductWidget from "./ProductWidgets/AutomationSuiteRobotsProductWidget";
import ProcessMiningProductWidget from "./ProductWidgets/ProcessMiningProductWidget";
import { SectionHeading } from "../RecommendationWidget/RecommendationWidget";
import styled from "styled-components/macro";
import PlatformProductWidget from "./ProductWidgets/PlatformProductWidget";
import * as constants from "../../../constants/calculatorConstants";


const SectionHeadingStyled = styled(SectionHeading)`
  margin-bottom: 10px;
`;

const AddProductSection = ({
  selectedProducts,
  onSelectedProductsChange,
  defaultConcurrentUsers,
  showDetails,
}: {
  selectedProducts: ProductOptionInterface[];
  onSelectedProductsChange: (data: ProductOptionInterface[]) => void;
  defaultConcurrentUsers?: number;
  showDetails: boolean;
}) => {
  const handleRemoveProduct = (selectedProduct: ProductOptionInterface) => {
    if (selectedProduct && selectedProduct.canBeRemoved) {
      const updatedProducts = selectedProducts.filter(
        (product: any) => product.key !== selectedProduct.key
      );
      onSelectedProductsChange(updatedProducts);
    }
  };

  const handleProductChange = (updatedProduct: ProductOptionInterface) => {
    const updateIndex = selectedProducts.findIndex(
      (currentProduct) => currentProduct.key === updatedProduct.key
    );
    if (updateIndex > -1) {
      selectedProducts[updateIndex] = updatedProduct;
      onSelectedProductsChange(selectedProducts);
    }
  };

  const renderProducts = () => {
      let isDUSelected = selectedProducts.findIndex(
        (option: any) => {
          return option.key === constants.DOCUMENT_UNDERSTANDING_PRODUCT_OPTION.key;
        }) >= 0;
      let isTMSelected = selectedProducts.findIndex(
        (option: any) => {
          return option.key === constants.TASK_MINING_PRODUCT_OPTION.key;
        }) >= 0;
      let isOrchDepSelected = selectedProducts.findIndex(
        (option: any) => {
          return (option.key === constants.ACTION_CENTER_PRODUCT_OPTION.key 
            || option.key === constants.INSIGHTS_PRODUCT_OPTION.key
            || option.key === constants.TEST_MANAGER_PRODUCT_OPTION.key
            || option.key === constants.AUTOMATION_SUITE_ROBOTS_PRODUCT_OPTION.key);
        }) >= 0;
      let isOtherServiceSelected = selectedProducts.findIndex(
        (option: any) => {
          return (option.key === constants.BUSINESS_APPS_PRODUCT_OPTION.key 
            || option.key === constants.AUTOMATION_HUB_PRODUCT_OPTION.key
            || option.key === constants.AUTOMATION_OPS_PRODUCT_OPTION.key
            || option.key === constants.DATA_SERVICE_PRODUCT_OPTION.key
            || option.key === constants.PROCESS_MINING_PRODUCT_OPTION.key);
        }) >= 0;
      let isAiCenterSelected = selectedProducts.findIndex(
        (option: any) => {
          return option.key === constants.AI_CENTER_PRODUCT_OPTION.key;
        }) >= 0;
    let isExternalOrchestratorEnabled = false;
    if (isAiCenterSelected) {
      let aiCenterProduct = selectedProducts.filter(p => p.key == constants.AI_CENTER_PRODUCT_OPTION.key)[0];
      if (aiCenterProduct.properties !== undefined) {
        isExternalOrchestratorEnabled = !!aiCenterProduct.properties["externalOrchestrator"];
      }
    }
    console.log(selectedProducts)


    return selectedProducts.map((product: any) => {
      switch (product.key) {
        case "platform":
          return (
            <PlatformProductWidget
              key={product.key}
              product={product}
              onRemove={() => handleRemoveProduct(product)}
              onProductChange={handleProductChange}
              showDetails={showDetails}
              canBeRemoved={isExternalOrchestratorEnabled && !isOtherServiceSelected? 1 : 0}
            />
          );
        case "orchestrator":
          return (
            <OrchestratorProductWidget
              key={product.key}
              product={product}
              onRemove={() => handleRemoveProduct(product)}
              onProductChange={handleProductChange}
              showDetails={showDetails}
              canBeRemoved={ isOrchDepSelected? 0 : 1}
            />
          );
        case "ai_center":
          return (
            <JobBasedProductWidget
              key={product.key}
              product={product}
              onRemove={() => handleRemoveProduct(product)}
              onProductChange={handleProductChange}
              showDetails={showDetails}
              canBeRemoved={isTMSelected || isDUSelected ? 0 : 1}
            />
          );
        case "document_understanding":
          return (
            <DocumentUnderstandingWidget
              key={product.key}
              product={product}
              onRemove={() => handleRemoveProduct(product)}
              onProductChange={handleProductChange}
              showDetails={true}
            />
          );
        case "task_mining":
        case "action_center":
        case "apps":
        case "automation_hub":
        case "automation_ops":
        case "data_service":
        case "test_manager":
          return (
            <GenericUsersProductWidget
              key={product.key}
              product={product}
              onRemove={() => handleRemoveProduct(product)}
              onProductChange={handleProductChange}
              defaultConcurrentUsers={defaultConcurrentUsers}
              showDetails={showDetails}
            />
          );
        case "automation_suite_robots":
          return (
            <AutomationSuiteRobotsProductWidget
                key={product.key}
                product={product}
                onRemove={() => handleRemoveProduct(product)}
                onProductChange={handleProductChange}
                  showDetails={true}
            />
          );
        case "process_mining":
          return (
            <ProcessMiningProductWidget
                key={product.key}
                product={product}
                onRemove={() => handleRemoveProduct(product)}
                onProductChange={handleProductChange}
                  showDetails={true}
            />
          );
        default:
          return (
            <ProductWidgetContainer
              key={product.key}
              productKey={product.key}
              title={product.name}
              onRemove={() => handleRemoveProduct(product)}
              canBeRemoved={product.canBeRemoved}
              showDetails={showDetails}
            />
          );
      }
    });
  };

  return (
    <div>
      <SectionHeadingStyled>Product selection</SectionHeadingStyled>
      {renderProducts()}
    </div>
  );
};

export default AddProductSection;
