import {
    Checkbox,
    FormControl,
    FormLabel,
    FormControlLabel,
    InputLabel,
    Switch,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Tooltip
} from "@material-ui/core";
import React from "react";
import styled from "styled-components/macro";
import {SectionHeading} from "../RecommendationWidget/RecommendationWidget";
import InputTextApollo from "../../inputs/InputTextApollo";
import {getNumberInputValue,} from "../../../utils/inputUtils";

const Container = styled.div`
  border: 1px solid var(--color-border);
  background: var(--color-background);
  border-radius: 3px;
  padding: 20px;
  margin-bottom: 20px;
`;

const FlexSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`;

const HighLevelConfig = ({
                             highLevelParameters,
                             onChangeHighLevelParameters,
                         }: {
    highLevelParameters: HighLevelParametersInterface;
    onChangeHighLevelParameters: (data: HighLevelParametersInterface) => void;
}) => {
    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {name} = e.currentTarget;
        const dataCopy: any = {...highLevelParameters};
        dataCopy[name] = !dataCopy[name];
        onChangeHighLevelParameters(dataCopy);
    };

    const handleProfileChange = (e: React.ChangeEvent<any>) => { // TODO: WTF is the right type
        const {value} = e.target;
        const dataCopy: any = {...highLevelParameters};
        dataCopy.profile = value;
        onChangeHighLevelParameters(dataCopy);
    };

    const handleFaultCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        const dataCopy: any = {...highLevelParameters};
        dataCopy.faultCount = Math.max(0, +value);
        onChangeHighLevelParameters(dataCopy);
    };

    const handleCoreCountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        const dataCopy: any = {...highLevelParameters};
        dataCopy.coreCount = Math.max(8, +value);
        onChangeHighLevelParameters(dataCopy);
    };

    const handleHostChange = (e: React.ChangeEvent<any>) => {
        const {value} = e.target;
        const dataCopy: any = {...highLevelParameters};
        dataCopy.host = value;
        onChangeHighLevelParameters(dataCopy);
    };

    const handleExternalS3Switch = (e: React.ChangeEvent<any>) => {
        const dataCopy: any = {...highLevelParameters};
        dataCopy.externalS3 = !dataCopy.externalS3;
        onChangeHighLevelParameters(dataCopy);
    };

    const handleBackupCheckbox = (e: React.ChangeEvent<any>) => {
        const dataCopy: any = {...highLevelParameters};
        dataCopy.backupEnabled = !dataCopy.backupEnabled;
        onChangeHighLevelParameters(dataCopy);
    }

    const handleMultiZoneCheckBox = (e: React.ChangeEvent<any>) => {
        const dataCopy: any = {...highLevelParameters};
        dataCopy.isMultizoanl = !dataCopy.isMultizoanl;
        onChangeHighLevelParameters(dataCopy);
    }

    return (
        <Container>
            <SectionHeading>Global Config</SectionHeading>
            <FlexSection>
                <div>
                    <InputLabel id="profile-label">Profile</InputLabel>
                    <FormControl fullWidth>
                        <Select
                            label="Profile"
                            labelId="profile-label"
                            value={highLevelParameters.profile}
                            onChange={handleProfileChange}
                        >
                            <MenuItem key={"eval"} value={"eval"}>Single-node Evaluation Only</MenuItem>
                            <MenuItem key={"ha"} value={"ha"}>Multi-nodes Production, HA Ready</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div>
                    <InputLabel id="options-label">Options</InputLabel>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={!!highLevelParameters.airGapped}
                                onChange={handleCheckboxChange}
                                name="airGapped"
                            />
                        }
                        label="Air Gapped Deployment"
                    />
                </div>
                <div>
                    <InputLabel id="options-label">Host</InputLabel>
                    <FormControl fullWidth>
                        <Select
                            label="Host"
                            value={highLevelParameters.host}
                            onChange={handleHostChange}
                        >
                            <MenuItem key={"aws"} value={"AWS"}>AWS Storage</MenuItem>
                            <MenuItem key={"azure"} value={"Azure"}>Azure Storage</MenuItem>
                            <MenuItem key={"on-prem"} value={"On-Prem"}>Other Cloud/On-Prem</MenuItem>
                        </Select>
                        {highLevelParameters.host === "On-Prem" && (
                            <FormControlLabel value="External S3"
                                              control={
                                                  <Switch
                                                      checked={!!highLevelParameters.externalS3}
                                                      onChange={handleExternalS3Switch}
                                                  />
                                              }
                                              label="External S3" />
                        )}
                    </FormControl>
                </div>
                <div>
                    <InputLabel id="options-label">Disaster Recovery</InputLabel>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={!!highLevelParameters.backupEnabled}
                                onChange={handleBackupCheckbox}
                            />
                        }
                        label="Enable Backup"
                    />
                    {highLevelParameters.profile === "ha" && (
                        <FormControlLabel
                            control={<Checkbox
                                checked={!!highLevelParameters.isMultizoanl}
                                onChange={handleMultiZoneCheckBox}/>} label="Multi Zonal"/>
                    )}
                </div>
                {highLevelParameters.profile === "ha" && (
                    <>
                        <div>
                            <Tooltip
                                title="Defines how many cluster nodes can go down without impacting the cluster (minimum 0).">
                                <InputLabel id="options-label">Node Fault Tolerance</InputLabel>
                            </Tooltip>
                            <InputTextApollo
                                //label="Faulty Nodes Tolerance"
                                variant="outlined"
                                inputProps={{type: "number"}}
                                InputProps={{
                                    name: "faultCount",
                                    value: getNumberInputValue(highLevelParameters.faultCount),
                                    onChange: handleFaultCountChange,
                                }}
                            />
                        </div>
                        <div>
                            <Tooltip
                                title="The number of VCPU a node has (minimum 8 vCPU, it is assumed the memory in GiB is at least 2x the vCPU count).">
                                <InputLabel id="options-label">Node Core Count</InputLabel>
                            </Tooltip>
                            <InputTextApollo
                                //label="Faulty Nodes Tolerance"
                                variant="outlined"
                                inputProps={{type: "number"}}
                                InputProps={{
                                    name: "coreCount",
                                    value: getNumberInputValue(highLevelParameters.coreCount),
                                    onChange: handleCoreCountChange,
                                }}
                            />
                        </div>
                    </>
                )}
            </FlexSection>
        </Container>
    );
};

export default HighLevelConfig;
