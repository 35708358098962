import {Accordion, AccordionDetails, AccordionSummary, Icon, IconButton, Typography} from "@material-ui/core";
import {ReactNode} from "react";
import styled from "styled-components/macro";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import withStyles from "@material-ui/core/styles/withStyles";
import orchestratorIcon from "../../../../assets/productIcons/orchestrator.png";
import {ICONS} from "../../../../constants/calculatorConstants";

const IconLeftAccordionSummary = withStyles({
    expandIcon: {
        order: -1,
        right: '1%',
    },
    root: {
        border: 5,
        borderRadius: 3,
        color: 'white',
        height: 50,
        padding: '0 7px',

    }
})(AccordionSummary);

const HeadingTypography = withStyles({
    root: {
        padding: '5px 0 0 0',
    }
})(Typography);

const CloseButtonContainer = styled.div`
  margin-left: auto;
`;

const ProductIcon = styled.img`
width: 18px;
height: 18px;
margin: 8px;
`;

const ProductWidgetContainer = ({   productKey,
                                    title,
                                    onRemove,
                                    children,
                                    canBeRemoved,
                                    showDetails,
                                }: {
    productKey: string;
    title: ReactNode;
    onRemove?: () => void;
    children?: ReactNode;
    canBeRemoved?: 0 | 1;
    showDetails: boolean;
}) => {
    return (
        <>  {console.log(productKey)}
            {!!showDetails && (
                <Accordion>
                    <IconLeftAccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >   <ProductIcon src={ICONS.get(productKey)}></ProductIcon>
                        <HeadingTypography variant="h4">{title}</HeadingTypography>
                        <CloseButtonContainer>
                            <IconButton onClick={onRemove} disabled={!canBeRemoved}>
                                <Icon>close</Icon>
                            </IconButton>
                        </CloseButtonContainer>
                    </IconLeftAccordionSummary>
                    <AccordionDetails>
                        <div>{children}</div>
                    </AccordionDetails>
                </Accordion>
            )}
            {!showDetails && (
                <Accordion expanded={false}>
                    <IconLeftAccordionSummary
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >   <ProductIcon src={ICONS.get(productKey)}></ProductIcon>
                        <HeadingTypography variant="h4">{title}</HeadingTypography>
                        <CloseButtonContainer>
                            <IconButton onClick={onRemove} disabled={!canBeRemoved}>
                                <Icon>close</Icon>
                            </IconButton>
                        </CloseButtonContainer>
                    </IconLeftAccordionSummary>
                </Accordion>
            )}
        </>
    );
};

export default ProductWidgetContainer;
