import React, { useState } from "react";
import styled from "styled-components/macro";
import uipathLogoImage from "./../../../assets/images/uipath-logo.svg";
import { ButtonApolloPrimary } from "../../Button/Button";
import AddProductModal from "../AddProductSection/AddProductModal/AddProductModal";
import { ALL_PRODUCT_OPTIONS } from "../../../constants/calculatorConstants";
import { Icon } from "@material-ui/core";
import { ButtonApolloSecondary } from "../../Button/Button";
import { jsonToQueryParamData } from "../../../utils/sharableCalculatorUtils";
import { copyTextToClipboard } from "../../../utils/inputUtils";
import { mdiLinkVariant, mdiOrderBoolAscendingVariant } from '@mdi/js';
import MdiIcon from '@mdi/react';

const ButtonContainer = styled.div`
  display: flex;
  place-items: center;
  font-size: 14px;
  min-height: 40px;
`;

const CheckIcon = styled(Icon)`
  color: var(--color-success-text);
`;

const HeadingContainer = styled.h1`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 0;
  gap: 10px;
`;

const H1 = styled.h1`
  color: var(--color-foreground);
  font-size: 1.4rem;
  display: flex;
  margin: 0;
  padding: 0;
  align-items: center;
  flex: 1;

  img {
    margin-right: 4px;
  }
`;

let copyTimeout: any = null;
const Heading = ({
  formData,
  selectedProducts,
  onSelectedProductsChange,
}: {
  formData: CalculatorFormDataType;
  selectedProducts: ProductOptionInterface[];
  onSelectedProductsChange: (data: ProductOptionInterface[]) => void;
}) => {
  const [isAddingProduct, setIsAddingProduct] = useState<boolean>(false);
  const [textWasCopied, setTextWasCopied] = useState(false);

  const handleCopyButtonClick = () => {
    clearTimeout(copyTimeout);
    copyTextToClipboard(
      `${window.location.href.split("?")[0]}?${jsonToQueryParamData(formData)}`
    );
    setTextWasCopied(true);
    copyTimeout = setTimeout(() => {
      setTextWasCopied(false);
    }, 2000);
  };

  const canAddProduct = () => {
    return selectedProducts.length === ALL_PRODUCT_OPTIONS.length;
  };

  return (
    <HeadingContainer>
      <H1>
        <img src={uipathLogoImage} alt="" /> Automation Suite Install Sizing
        Calculator
      </H1>

      <ButtonContainer>
        <ButtonApolloSecondary onClick={handleCopyButtonClick} startIcon={<MdiIcon path={mdiLinkVariant} size={1} />}>
          {textWasCopied ? "Copied to Clipboard" : "Copy Shareable URL"}
        </ButtonApolloSecondary>
      </ButtonContainer>
      <ButtonContainer>
        {!canAddProduct() ? (
          <ButtonContainer>
            <ButtonApolloPrimary onClick={() => setIsAddingProduct(true)} startIcon={<MdiIcon path={mdiOrderBoolAscendingVariant} size={1} />}>
              Change Product Selection
            </ButtonApolloPrimary>
          </ButtonContainer>
        ) : (
          <ButtonContainer>
            <CheckIcon fontSize="large">check</CheckIcon> All Products Added
          </ButtonContainer>
        )}
        {isAddingProduct && (
          <AddProductModal
            onClose={() => setIsAddingProduct(false)}
            selectedProducts={selectedProducts}
            onSelectedProductsChange={onSelectedProductsChange}
          />
        )}
      </ButtonContainer>
    </HeadingContainer>
  );
};

export default Heading;
