import React, { ReactNode, useEffect, useRef } from "react";
import styled, { css } from "styled-components/macro";
import { TextField } from "@material-ui/core";

const CounterLabel = styled.span`
  min-width: 38px;
  padding-left: 5px;
  color: var(--color-foreground-de-emp);
  font-size: 0.875rem;
`;

const TextFieldWithStyle = styled(TextField)<{ $maxLength?: number }>(
  (props) => css`
    ${typeof props?.$maxLength === "number"
      ? css`
          .MuiOutlinedInput-inputAdornedEnd {
            width: unset;
            flex-grow: 1;
          }
        `
      : ""}
    .MuiInputLabel-outlined {
      z-index: unset;
    }
  `
);

const InputTextApollo = ({
  label,
  placeholder,
  helperText,
  className,
  variant = "outlined",
  error = false,
  errorCount,
  InputLabelProps = {},
  InputProps = {},
  inputProps = {},
  required,
  disabled,
  multiline,
  maxLength,
  rows,
}: {
  label?: string | ReactNode;
  placeholder?: string;
  helperText?: string;
  className?: string;
  variant?: "outlined" | "filled" | "standard" | undefined;
  error?: boolean;
  errorCount?: number;
  InputLabelProps?: any;
  InputProps?: any;
  inputProps?: any;
  maxLength?: number;
  required?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number | string;
}) => {
  const inputEl = useRef(null);

  useEffect(() => {
    if (inputEl && inputEl.current && error) {
      const el: any = inputEl.current;
      el?.scrollIntoView?.({ block: "center" });
    }
  }, [inputEl, error, errorCount]);

  const allInputProps = InputProps
    ? {
        ...InputProps,
      }
    : {};

  const valueLength = allInputProps?.value?.length || 0;

  if (typeof maxLength === "number" && maxLength > 0) {
    if (valueLength + 10 >= maxLength) {
      allInputProps.endAdornment = (
        <CounterLabel>{`${valueLength}/${maxLength}`}</CounterLabel>
      );
    }

    allInputProps.onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        typeof e?.target?.value === "string" &&
        e.target.value.length > maxLength
      ) {
        e.target.value = e.target.value.substring(0, maxLength);
      }
      typeof InputProps.onChange === "function" && InputProps.onChange(e);
    };
  }

  return (
    <TextFieldWithStyle
      ref={inputEl}
      className={className}
      label={label}
      placeholder={placeholder}
      variant={variant}
      error={error ? true : undefined}
      InputLabelProps={InputLabelProps}
      InputProps={allInputProps}
      inputProps={inputProps}
      helperText={helperText}
      required={required}
      disabled={disabled}
      $maxLength={maxLength}
      multiline={multiline}
      rows={rows}
    />
  );
};

export default InputTextApollo;
