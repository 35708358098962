import React from "react";
import {
  getNumberInputValue,
  getNumberOrUndefinedFromString,
} from "../../../../utils/inputUtils";
import InputTextApollo from "../../../inputs/InputTextApollo";
import ProductWidgetContainer from "./ProductWidgetContainer";

const OrchestratorProductWidget = ({
  product,
  onRemove,
  onProductChange,
  showDetails,
  canBeRemoved,
}: any) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.currentTarget;
    if (product?.properties && name) {
      const productCopy = { ...product };
      productCopy.properties = { ...productCopy.properties };
      productCopy.properties[name] = getNumberOrUndefinedFromString(value);
      onProductChange(productCopy);
    }
  };

  return (
    <ProductWidgetContainer
      onRemove={onRemove}
      title={product.name}
      productKey={product.key}
      canBeRemoved={canBeRemoved}
      showDetails={showDetails}
    >
      <InputTextApollo
        label="Robots"
        variant="outlined"
        inputProps={{ type: "number" }}
        InputProps={{
          name: "concurrentRobots",
          value: getNumberInputValue(product.properties.concurrentRobots),
          onChange: handleInputChange,
        }}
      />
    </ProductWidgetContainer>
  );
};

export default OrchestratorProductWidget;
